import { DotFillIcon } from '@primer/octicons-react';
import { Button } from 'react-bootstrap';

function SummaryItem(props: any) {
    return (
        <Button className={`${props.className} bg-transparent border-0 p-0 text-dark`} onClick={props.onClick}>
            <div className="bg-white p-3 rounded-3 shadow-sm flex-grow-1 d-flex justify-content-between align-items-center text-start gap-3">
                <div>
                    <div className="h5 mb-0">{props.title}</div>
                    <div className="fw-light fs-7">{props.subtitle}</div>
                </div>
                <div className="d-flex align-items-center">
                    {props.value} <DotFillIcon className={`text-${props.color}`} size={16} />
                </div>
            </div>
        </Button>
    );
}

export default SummaryItem;
