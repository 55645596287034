import { useState, useEffect } from 'react';
import './EditJob.css';

import { Button, Form, Modal, Spinner } from 'react-bootstrap';
import { Job } from '../../services/jobs.service';

function EditJob(props: any) {
    const [data, setData] = useState<Job>(props.job);
    const [curError, setCurError] = useState<string | null>(null);
    const [awaiting, setAwaiting] = useState(false);
    const [updateRequired, setUpdateRequired] = useState(false);

    useEffect(() => {
        setData(props.job);
    }, [props.job]);

    const handleClose = () => {
        setCurError(null);
        setUpdateRequired(false);
        props.setShow(false);
    };

    function handleUpdate() {
        console.log('Handling Update');
        setAwaiting(true);
        props.updateJob(data, setCurError, setAwaiting);
    }

    function handleDataChange(event: any) {
        const value = event.target.type === 'checkbox' ? event.target.checked : event.target.value;

        setData({
            ...data,
            [event.target.name]: value,
        });
    }

    useEffect(() => {
        if (data !== null) {
            if (
                data.description !== props.job.description ||
                data.state !== props.job.state ||
                data.schedule !== props.job.schedule ||
                data.revisionNumber !== props.job.revisionNumber
            ) {
                setUpdateRequired(true);
            } else {
                setUpdateRequired(false);
            }
        }
    }, [data]);

    return (
        <>
            {props.job !== null && (
                <Modal fullscreen={'md-down'} show={props.show} onHide={handleClose} centered>
                    <Modal.Header closeButton>
                        <Modal.Title>Edit Job</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Form>
                            <Form.Group className="mb-3">
                                <Form.Label>Workflow Key</Form.Label>
                                <Form.Control
                                    name="workflowKey"
                                    placeholder={props.job.workflowKey}
                                    disabled
                                    className="rounded-0"
                                />
                            </Form.Group>
                            <Form.Group className="mb-3">
                                <Form.Label>Name</Form.Label>
                                <Form.Control name="name" placeholder={props.job.name} disabled className="rounded-0" />
                            </Form.Group>
                            <Form.Group className="mb-3">
                                <Form.Label>Revision Number</Form.Label>
                                <Form.Control
                                    as="select"
                                    name="revisionNumber"
                                    onChange={handleDataChange}
                                    className="rounded-0"
                                >
                                    {props.taskDefinitions.map((task: any) => {
                                        const jobRevisionNumber = parseInt(props.job.revisionNumber);
                                        if (task.revision === jobRevisionNumber) {
                                            return (
                                                <option className="text-muted" key={task.revision} value={task.revision}>
                                                    CPU: {task.cpu} Memory: {task.memory} Mbs
                                                </option>
                                            );
                                        }
                                    })}
                                    {props.taskDefinitions.map((task: any) => {
                                        const jobRevisionNumber = parseInt(props.job.revisionNumber);
                                        if (task.revision !== jobRevisionNumber) {
                                            return (
                                                <option className="text-dark" key={task.revision} value={task.revision}>
                                                    CPU: {task.cpu} Memory: {task.memory} Mbs
                                                </option>
                                            );
                                        }
                                    })}
                                </Form.Control>
                            </Form.Group>
                            <Form.Group className="mb-3">
                                <Form.Label>Schedule</Form.Label>
                                <Form.Control
                                    name="schedule"
                                    type="text"
                                    defaultValue={props.job.schedule}
                                    placeholder="Enter schedule"
                                    onChange={handleDataChange}
                                    className="rounded-0"
                                />
                            </Form.Group>
                            <Form.Group className="mb-3" controlId="formBasicCheckbox">
                                <Form.Check
                                    name="state"
                                    type="switch"
                                    label="Is Enabled?"
                                    defaultChecked={props.job.state}
                                    onChange={handleDataChange}
                                    className="rounded-0"
                                />
                            </Form.Group>
                            <Form.Group className="mb-3">
                                <Form.Label>Description</Form.Label>
                                {/* TODO: TRY TO FIND TEXTAREA COMPONTENT IN REACT-BOOTSTRAP */}
                                <textarea
                                    name="description"
                                    defaultValue={props.job.description}
                                    placeholder="Enter description"
                                    onChange={handleDataChange}
                                    className="rounded-0 form-control"
                                />
                            </Form.Group>
                        </Form>
                        {curError !== null && <p className="text-danger">{curError}</p>}
                    </Modal.Body>
                    <Modal.Footer>
                        <Button onClick={handleClose} className="bg-transparent text-dark rounded-0 border-0">
                            Close
                        </Button>
                        <Button
                            onClick={handleUpdate}
                            className={`bg-${awaiting ? 'secondary' : 'main-1'} ${updateRequired ? '' : 'disabled'
                                } rounded-0 border-0 modal-button-width`}
                        >
                            {awaiting && <Spinner animation="border" className="w-10" size="sm" />}
                            {!awaiting && <>Update Job</>}
                        </Button>
                    </Modal.Footer>
                </Modal>
            )}
        </>
    );
}

export default EditJob;
