import { useState, useEffect } from 'react';
import Select from 'react-select';
import './AddJob.css';

import { Button, Form, Modal, Spinner } from 'react-bootstrap';
import { CreateScheduleDto, emptyCreateScheduleDto, addJob, getWorkflowsKeys, getTaskDefinitions } from '../../services/jobs.service';

function AddJob(props: any) {
    const [data, setData] = useState<CreateScheduleDto>(emptyCreateScheduleDto);

    const [awaiting, setAwaiting] = useState(false);
    const [dataValid, setDataValid] = useState(false);
    const [curError, setCurError] = useState<string | null>(null);
    const [cancelHandler, setCancelHandler] = useState<AbortController | null>(null);
    const [workFlowFetched, setWorkFlowFetched] = useState(false);
    const [workFlowKeys, setWorkFlowKeys] = useState<string[]>([]);
    const [workFlowOptions, setWorkFlowOptions] = useState<{ value: string; label: string }[]>([]);
    const [taskDefinitions, setTaskDefinitions] = useState<any>([]);
    // const handleClose = () => props.setShow(false);
    function handleClose() {
        props.setShow(false);
        setCurError(null);
        setData(emptyCreateScheduleDto);
    }

    async function handleInsertion() {
        // console.log('Handling Insertion');

        setAwaiting(true);
        const result = await addJob(data, setCurError, setCancelHandler);
        if (result) {
            props.callback();
            handleClose();
        }
        setAwaiting(false);
    }

    async function fetchWorkflows() {
        let taskDefs = await getTaskDefinitions(setCancelHandler);
        setTaskDefinitions(taskDefs);

        let workFlows = await getWorkflowsKeys(setCancelHandler);
        let workFlowskeys: string[] = [];
        for (let workflow in workFlows) {
            workFlowskeys.push(workFlows[workflow][0].key);
        }
        setWorkFlowKeys(workFlowskeys);
        let options = workFlowskeys.map((key: string) => ({ value: key, label: key }));
        setWorkFlowOptions(options);
    }

    function isValidInput(input: string) {
        return input !== null && input.length !== 0;
    }

    // Checking if the entered values pass input validation
    useEffect(() => {
        if (props.show) {
            // If the modal is shown, fetch the workflows if not already fetched
            if (!workFlowFetched) {
                fetchWorkflows();
                setWorkFlowFetched(true);
            }
        }
        setDataValid(
            isValidInput(data.name) &&
            isValidInput(data.cronExpression) &&
            isValidInput(data.description) &&
            isValidInput(data.workflowKey),
            // we are not checking for revisionNumber as it is optional
        );
    }, [data, props.show]);

    function handleDataChange(event: any) {
        const value = event.target.type === 'checkbox' ? event.target.checked : event.target.value;
        setData({
            ...data,
            [event.target.name]: value,
        });
    }

    return (
        <>
            <Modal show={props.show} onHide={handleClose} centered>
                <Modal.Header closeButton>
                    <Modal.Title>Schedule a Job</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {workFlowKeys.length === 0 && (
                        // If workFlowKeys is empty, show a spinner (response is being awaited)
                        <div className="text-center">
                            <Spinner animation="border" role="status"></Spinner>
                        </div>
                    )}
                    {workFlowKeys.length > 0 && (
                        <Form>
                            {/* <Form.Group className="mb-3">
                            <Form.Label>ID</Form.Label>
                            <Form.Control name="id" placeholder="Enter id" onChange={handleDataChange}  className="rounded-0" />
                        </Form.Group> */}
                            <Form.Group className="mb-3">
                                {/* <Form.Label>Name</Form.Label> */}
                                <Form.Control
                                    name="name"
                                    type="text"
                                    placeholder="Enter name"
                                    onChange={handleDataChange}
                                    className="rounded-0"
                                />
                                <Form.Text className="text-muted">Name must be unique.</Form.Text>
                            </Form.Group>
                            <Form.Group className="mb-3">
                                {/* <Form.Label>Schedule</Form.Label> */}
                                <Form.Control
                                    name="cronExpression"
                                    type="text"
                                    placeholder="Enter cron expression"
                                    onChange={handleDataChange}
                                    className="rounded-0"
                                />
                                <Form.Text className="text-muted">Examples: cron(0 9 ? * * *), rate(14 days)</Form.Text>
                            </Form.Group>
                            <Form.Group className="mb-3">
                                {/* <Form.Label>Workflow Key</Form.Label> */}
                                {/* <Form.Control
                                name="workflowKey"
                                type="text"
                                placeholder="Enter workflowKey"
                                onChange={handleDataChange}
                                className="rounded-0"
                                onClick={test}
                            /> */}
                                <Select
                                    placeholder="Enter workflow key"
                                    options={workFlowOptions}
                                    onChange={(selectedOption) => handleDataChange({
                                        target: {
                                            name: 'workflowKey',
                                            value: selectedOption ? (selectedOption as { value: string }).value : '',
                                            type: 'select-one'
                                        }
                                    })}
                                />
                                <Form.Text className="text-muted">
                                    Example: Test/standard_validation::job_config.yaml
                                </Form.Text>
                            </Form.Group>
                            <Form.Group className="mb-3">
                                <Form.Control
                                    as="select"
                                    name="revisionNumber"
                                    onChange={handleDataChange}
                                    className="rounded-0"
                                >
                                    <option className="text-muted" value="">Select System Configuration (Optional)</option>
                                    {taskDefinitions.map((task: any) => (
                                        <option className='text-dark' key={task.revision} value={task.revision}>
                                            CPU: {task.cpu} Memory: {task.memory} Mbs
                                        </option>
                                    ))}
                                </Form.Control>
                                <Form.Text className="text-muted">
                                    Example: CPU: 4096 Memory: 30720 Mbs
                                </Form.Text>
                            </Form.Group>
                            <Form.Group className="mb-3">
                                {/* <Form.Label>Description</Form.Label> */}
                                <Form.Control
                                    name="description"
                                    type="text"
                                    placeholder="Enter description"
                                    onChange={handleDataChange}
                                    className="rounded-0"
                                />
                            </Form.Group>
                            {curError !== null && <p className="text-danger">{curError}</p>}
                        </Form>
                    )}
                </Modal.Body>
                <Modal.Footer>
                    <Button
                        onClick={handleClose}
                        className={`bg-transparent ${awaiting ? 'disabled' : ''} text-dark rounded-0 border-0`}
                    >
                        Close
                    </Button>
                    <Button
                        onClick={handleInsertion}
                        className={`bg-main-1 ${dataValid ? '' : 'disabled'} rounded-0 border-0 modal-button-width`}
                    >
                        {awaiting && <Spinner animation="border" className="w-10" size="sm" />}
                        {!awaiting && <>Schedule</>}
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    );
}

export default AddJob;
