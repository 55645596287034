import { useState, useEffect } from 'react';
import './ViewHistory.css';

import { Modal, Button, Spinner, Accordion, Container, ProgressBar } from 'react-bootstrap';

import { getWorkflowHistory, workflowHistory, retryJob } from '../../services/jobs.service';
import { timestampToDate } from '../../services/date.service';
import {
    CheckCircleFillIcon,
    CloudOfflineIcon,
    XCircleFillIcon,
    AlertFillIcon,
    IssueReopenedIcon,
    ChevronRightIcon,
    ChevronLeftIcon,
    HistoryIcon,
} from '@primer/octicons-react';
import LiveStats from '../LiveStats/LiveStats';

function ViewHistory(props: any) {
    const pageLimit = 10;

    const [curWorkflowHistory, setCurWorkflowHistory] = useState<workflowHistory[] | null>(null);

    const [pages, setPages] = useState<number>(1);
    const [curPage, setCurPage] = useState<number>(1);

    const [successMsg, setSuccessMsg] = useState<string | null>(null);
    const [errorMsg, setErrorMsg] = useState<string | null>(null);
    const [awaiting, setAwaiting] = useState(false);
    const [cancelHandler, setCancelHandler] = useState<AbortController | null>(null);

    const sampleLiveStats = {
        'httpcompression/response_bytes': 22971113832,
        'crawlera/delay/reset_backoff': 258646,
        'log_count/INFO': 518409,
        'downloader/request_bytes': 558699480,
        'httpcache/store': 258646,
        progress_percentage: '63.71%',
        'scheduler/dequeued': 258933,
        'crawlera/request': 258933,
        'httpcache/firsthand': 258646,
        'retry/reason_count/twisted.internet.error.TimeoutError': 178,
        'scheduler/enqueued': 258933,
        'downloader/response_count': 258649,
        'retry/reason_count/504 Gateway Time-out': 3,
        'scheduler/dequeued/disk': 258933,
        'retry/reason_count/twisted.web._newclient.ResponseFailed': 6,
        'downloader/response_bytes': 2603522155,
        'scheduler/enqueued/disk': 258933,
        update_time: '2023-05-04T05:33:25.152213',
        'memusage/startup': 868491264,
        item_scraped_count: 1927442,
        'downloader/exception_type_count/twisted.internet.error.TimeoutError': 178,
        'downloader/exception_type_count/twisted.web._newclient.ResponseFailed': 6,
        response_received_count: 258646,
        'crawlera/request/method/GET': 258933,
        'downloader/response_status_count/504': 3,
        'httpcompression/response_count': 258646,
        'httpcache/miss': 258746,
        'log_count/WARNING': 7,
        'retry/count': 187,
        start_time: '2023-05-03T12:48:53.002444',
        'downloader/request_method_count/GET': 258933,
        'downloader/response_status_count/200': 258646,
        'crawlera/response/status/200': 258646,
        'crawlera/response': 258646,
        'memusage/max': 943865856,
        'downloader/request_count': 258933,
        'downloader/exception_count': 184,
    };

    async function fetchWorkflowHistory() {
        setCurWorkflowHistory(null);
        setPages(0);
        setCurPage(1);

        let history: workflowHistory[] | null = await getWorkflowHistory(props.job.workflowKey, setCancelHandler);

        if (history === null) {
            return;
        }

        setCurWorkflowHistory(history);

        // Calculate the number of pages
        let pages = Math.ceil(history.length / pageLimit);

        setPages(pages);
        setCurPage(1);

        // console.log(pages);
        // console.log(history);
    }

    function previousPage() {
        if (curPage > 1) {
            setCurPage((prev) => {
                return prev - 1;
            });
        }
    }

    function nextPage() {
        if (curPage < pages) {
            setCurPage((prev) => {
                return prev + 1;
            });
        }
    }

    function getPaginatedArray() {
        if (curWorkflowHistory === null) {
            return [];
        }

        let start = (curPage - 1) * pageLimit;
        let end = start + pageLimit;

        // return curWorkflowHistory.slice().reverse().slice(start, end) // Not doing reverse since it's being handled on the backend.
        return curWorkflowHistory.slice().slice(start, end);
    }

    useEffect(() => {
        if (props.show) {
            setSuccessMsg(null);
            setErrorMsg(null);
            fetchWorkflowHistory();
        }
    }, [props.show]);

    const handleClose = () => {
        setCurWorkflowHistory(null);
        props.setShow(false);
    };

    async function handleRetry(workflowKey: string, jobId: string) {
        setAwaiting(true);
        setSuccessMsg(null);
        setErrorMsg(null);

        console.log(workflowKey + ' ' + jobId + ' ' + props.job.name);

        await retryJob(workflowKey, jobId, props.job.name, undefined, setSuccessMsg, setErrorMsg, setCancelHandler);

        setAwaiting(false);
    }

    return (
        <>
            <Modal fullscreen={'md-down'} size="xl" show={props.show} onHide={handleClose} centered>
                <Modal.Header closeButton>
                    <Modal.Title className="text-break h5">{props.job && props.job.workflowKey}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {curWorkflowHistory === null && (
                        <div className="d-flex justify-content-center">
                            <Spinner animation="border" />
                        </div>
                    )}
                    {curWorkflowHistory !== null && curWorkflowHistory.length === 0 && (
                        <div className="d-flex justify-content-center gap-2">
                            <CloudOfflineIcon size={20} />
                            No History Found
                        </div>
                    )}
                    {curWorkflowHistory !== null && curWorkflowHistory.length > 0 && (
                        <Accordion
                            flush
                            onSelect={() => {
                                setSuccessMsg(null);
                                setErrorMsg(null);
                            }}
                        >
                            {getPaginatedArray().map((historyItem, index) => (
                                <Accordion.Item
                                    eventKey={`${historyItem.startedAt.toString()}-${index}`}
                                    key={`${historyItem.startedAt.toString()}-${index}`}
                                >
                                    <Accordion.Header>
                                        <Container fluid>
                                            <div className="d-flex gap-4 flex-wrap align-items-center">
                                                {historyItem.status === 'SUCCESS' && (
                                                    <CheckCircleFillIcon className="text-success" size={24} />
                                                )}
                                                {historyItem.status === 'ERROR' && (
                                                    <XCircleFillIcon className="text-danger" size={24} />
                                                )}
                                                {historyItem.status === 'RUNNING' && (
                                                    <IssueReopenedIcon className="text-secondary rotate" size={24} fill='#7444FF' />
                                                )}
                                                {historyItem.workflowName}
                                                <div>
                                                    <span className="fw-bold">Started: </span>
                                                    {timestampToDate(historyItem.startedAt)}
                                                </div>
                                                {historyItem.status === 'SUCCESS' && (
                                                    <div>
                                                        <span className="fw-bold">Finished: </span>
                                                        {timestampToDate(historyItem.finishedAt)}
                                                    </div>
                                                )}
                                                <div>
                                                    <span className="fw-bold">By: </span>
                                                    {historyItem.startedBy}
                                                </div>
                                                <div>
                                                    <span className="fw-bold">Tasks: </span>
                                                    {historyItem.taskExecutions.length}
                                                </div>
                                            </div>
                                        </Container>
                                    </Accordion.Header>
                                    <Accordion.Body>
                                        <Accordion>
                                            {historyItem.taskExecutions !== null &&
                                                historyItem.taskExecutions.map((execution, index) => (
                                                    <Accordion.Item
                                                        eventKey={`${execution.startedAt.toString()}-${index}`}
                                                        key={`${execution.startedAt.toString()}-${index}`}
                                                    >
                                                        <Accordion.Header>
                                                            <Container fluid>
                                                                <div className="d-flex gap-4 flex-wrap align-items-center">
                                                                    {execution.status === 'SUCCESS' && (
                                                                        <CheckCircleFillIcon
                                                                            className="text-success"
                                                                            size={24}
                                                                        />
                                                                    )}
                                                                    {execution.status === 'ERROR' && (
                                                                        <XCircleFillIcon
                                                                            className="text-danger"
                                                                            size={24}
                                                                        />
                                                                    )}
                                                                    {execution.status === 'RUNNING' && (
                                                                        <IssueReopenedIcon
                                                                            className="text-secondary rotate"
                                                                            size={24}
                                                                            fill='#7444FF'
                                                                        />
                                                                    )}
                                                                    {execution.status === 'PENDING' && (
                                                                        <HistoryIcon
                                                                            className="text-secondary"
                                                                            size={24}
                                                                        />
                                                                    )}
                                                                    {execution.status !== 'SUCCESS' &&
                                                                        execution.status !== 'ERROR' &&
                                                                        execution.status !== 'RUNNING' &&
                                                                        execution.status !== 'PENDING' && (
                                                                            <AlertFillIcon
                                                                                className="text-secondary"
                                                                                size={24}
                                                                            />
                                                                        )}
                                                                    {execution.name}
                                                                    <div>
                                                                        <span className="fw-bold">Started: </span>
                                                                        {timestampToDate(execution.startedAt)}
                                                                    </div>
                                                                    {execution.status === 'SUCCESS' && (
                                                                        <div>
                                                                            <span className="fw-bold">Finished: </span>
                                                                            {timestampToDate(execution.finishedAt)}
                                                                        </div>
                                                                    )}
                                                                    <div>
                                                                        <span className="fw-bold">Attempts: </span>
                                                                        {execution.attempts}
                                                                    </div>
                                                                </div>
                                                            </Container>
                                                        </Accordion.Header>
                                                        <Accordion.Body>
                                                            <Container>
                                                                <div className="d-flex table-responsive">
                                                                    <table className="table table-hover">
                                                                        <tbody>
                                                                            {execution.history.map((execHistory) => (
                                                                                <tr
                                                                                    key={`${execHistory.status}-${execHistory.timestamp}`}
                                                                                >
                                                                                    <td>
                                                                                        {execHistory.status ===
                                                                                            'PENDING' && (
                                                                                                <HistoryIcon
                                                                                                    className="text-secondary"
                                                                                                    size={24}
                                                                                                />
                                                                                            )}
                                                                                        {execHistory.status ===
                                                                                            'STARTED' && (
                                                                                                <AlertFillIcon
                                                                                                    className="text-secondary"
                                                                                                    size={24}
                                                                                                />
                                                                                            )}
                                                                                        {execHistory.status ===
                                                                                            'SUCCESS' && (
                                                                                                <CheckCircleFillIcon
                                                                                                    className="text-success"
                                                                                                    size={24}
                                                                                                />
                                                                                            )}
                                                                                        {execHistory.status ===
                                                                                            'ERROR' && (
                                                                                                <XCircleFillIcon
                                                                                                    className="text-danger"
                                                                                                    size={24}
                                                                                                />
                                                                                            )}
                                                                                    </td>
                                                                                    <td>{execHistory.status}</td>
                                                                                    <td>
                                                                                        <span className="fw-bold">
                                                                                            Timestamp:{' '}
                                                                                        </span>
                                                                                        {timestampToDate(
                                                                                            execHistory.timestamp,
                                                                                        )}
                                                                                    </td>
                                                                                </tr>
                                                                            ))}
                                                                        </tbody>
                                                                    </table>
                                                                </div>
                                                                {execution.status === 'RUNNING' &&
                                                                    Object.keys(execution.liveStats).length > 0 && (
                                                                        <LiveStats
                                                                            liveStats={execution.liveStats}
                                                                            type={execution.type}
                                                                            xs={12}
                                                                            sm={8}
                                                                            md={6}
                                                                        />
                                                                    )}
                                                                {execution.status !== 'RUNNING' &&
                                                                    Object.keys(execution.stats).length > 0 &&
                                                                    execution.stats.length > 0 && execution.stats[execution.stats.length - 1] !== null && (
                                                                        <LiveStats
                                                                            liveStats={
                                                                                execution.stats[
                                                                                execution.stats.length - 1
                                                                                ]
                                                                            }
                                                                            type={execution.type}
                                                                            xs={12}
                                                                            sm={8}
                                                                            md={6}
                                                                        />
                                                                    )}
                                                            </Container>
                                                        </Accordion.Body>
                                                    </Accordion.Item>
                                                ))}
                                        </Accordion>
                                        {successMsg && (
                                            <div className="alert alert-success" role="alert">
                                                {successMsg}
                                            </div>
                                        )}
                                        {errorMsg && (
                                            <div className="alert alert-danger" role="alert">
                                                {errorMsg}
                                            </div>
                                        )}
                                        <div className="d-flex justify-content-end align-items-center gap-2">
                                            {historyItem.logsLink !== null && (
                                                <a
                                                    href={historyItem.logsLink}
                                                    className="btn bg-transparent text-primary border-0"
                                                    target="_blank"
                                                >
                                                    View Logs
                                                </a>
                                            )}
                                            {historyItem.status !== 'RUNNING' && (
                                                <Button
                                                    onClick={() =>
                                                        handleRetry(historyItem.workflowKey, historyItem.jobId)
                                                    }
                                                    className={`bg-${awaiting ? 'secondary' : 'main-1'
                                                        } rounded-0 border-0 modal-button-width`}
                                                >
                                                    {awaiting && (
                                                        <Spinner animation="border" className="w-10" size="sm" />
                                                    )}
                                                    {!awaiting && <>Restart Job</>}
                                                </Button>
                                            )}
                                        </div>
                                    </Accordion.Body>
                                </Accordion.Item>
                            ))}
                        </Accordion>
                    )}
                </Modal.Body>
                <Modal.Footer>
                    {pages > 1 && (
                        <div className="d-flex gap-3 flex-wrap justify-content-center align-items-center">
                            <Button
                                onClick={() => {
                                    previousPage();
                                }}
                                className={`bg-transparent rounded-0 border-0 p-0 ${curPage === 1 ? 'disabled text-dark' : 'text-primary'
                                    }`}
                            >
                                <ChevronLeftIcon size={18} />
                                Previous
                            </Button>
                            {(() => {
                                const items = [];
                                for (let i = 1; i <= pages; i++) {
                                    items.push(
                                        <Button
                                            key={i}
                                            onClick={() => {
                                                setCurPage(i);
                                            }}
                                            className={`bg-transparent rounded-0 border-0 p-0 ${curPage === i ? 'disabled text-dark' : 'text-primary'
                                                }`}
                                        >
                                            {i}
                                        </Button>,
                                    );
                                }
                                return items;
                            })()}
                            <Button
                                onClick={() => {
                                    nextPage();
                                }}
                                className={`bg-transparent rounded-0 border-0 p-0 ${curPage === pages ? 'disabled text-dark' : 'text-primary'
                                    }`}
                            >
                                Next
                                <ChevronRightIcon size={18} />
                            </Button>
                        </div>
                    )}
                    <Button onClick={handleClose} className="bg-transparent text-dark rounded-0 border-0">
                        Close
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    );
}

export default ViewHistory;
