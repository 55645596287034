import { Offcanvas, Accordion, Container, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { timestampToDate } from '../../services/date.service';
import {
    CheckCircleFillIcon,
    CloudOfflineIcon,
    XCircleFillIcon,
    AlertFillIcon,
    IssueReopenedIcon,
    HistoryIcon,
} from '@primer/octicons-react';
import { copyToClipboard, generatePackageGithubUrl, workflowHistory, downloadECSFileChunkDto, emptyDownloadECSFileChunkDto, downloadECSFileChunk } from '../../services/jobs.service';
import LiveStats from '../LiveStats/LiveStats';
import { useState } from 'react';
import './Offcanvas.css';

function CompletedOffcanvas(props: any) {
    const [copyTooltip, setCopyTooltip] = useState('Copy to clipboard');
    const [cancelHandler, setCancelHandler] = useState<AbortController | null>(null);
    const [successMsg, setSuccessMsg] = useState<string | null>(null);
    const [errorMsg, setErrorMsg] = useState<string | null>(null);

    async function downloadFileChunk(workflowName: string, jobId: string, taskName: string) {
        setSuccessMsg(null);
        setErrorMsg(null);

        let data: downloadECSFileChunkDto = emptyDownloadECSFileChunkDto;
        data.workflowName = workflowName;
        data.jobId = jobId;
        data.taskName = taskName;
        console.log('Data:', data);

        await downloadECSFileChunk(data, setCancelHandler, setSuccessMsg, setErrorMsg);

        data.workflowName = '';
        data.jobId = '';
        data.taskName = '';
    }

    return (
        <Offcanvas show={props.completedShow} onHide={props.handleCompletedToggle} placement={'end'}>
            <Offcanvas.Header closeButton>
                <Offcanvas.Title>Completed Jobs</Offcanvas.Title>
            </Offcanvas.Header>
            <Offcanvas.Body>
                {props.summary !== null && props.summary.successful.length === 0 && (
                    <div className="d-flex justify-content-center gap-2">
                        <CloudOfflineIcon size={20} />
                        No Records Found
                    </div>
                )}
                {props.summary !== null && props.summary.successful.length > 0 && (
                    <Accordion flush>
                        {/* onSelect={()=>{setSuccessMsg(null);setErrorMsg(null)}}> */}
                        {props.summary.successful.map((historyItem: workflowHistory) => (
                            <Accordion.Item
                                eventKey={`${historyItem.startedAt.toString()}-${historyItem.jobId.toString()}`}
                                key={`${historyItem.startedAt.toString()}-${historyItem.jobId.toString()}`}
                            >
                                <Accordion.Header>
                                    <Container fluid>
                                        <div className="d-flex gap-2 text-break align-items-center">
                                            <span className={`badge rounded-pill text-bg-primary`}>
                                                {timestampToDate(historyItem.startedAt)}
                                            </span>
                                            {historyItem.workflowName}
                                        </div>
                                    </Container>
                                </Accordion.Header>
                                <Accordion.Body>
                                    <Accordion>
                                        <div className="d-flex gap-1 mb-2">
                                            <OverlayTrigger
                                                placement="left"
                                                delay={{ show: 250, hide: 250 }}
                                                overlay={<Tooltip>Open on Github</Tooltip>}
                                            >
                                                <a
                                                    href={generatePackageGithubUrl(historyItem.workflowKey)}
                                                    className="fs-9 fw-light text-bg-secondary badge rounded-pill py-1 border-0 text-decoration-none"
                                                    target="_bank"
                                                >
                                                    {historyItem.workflowKey.split('::')[1]}
                                                </a>
                                            </OverlayTrigger>

                                            <OverlayTrigger
                                                placement="right"
                                                delay={{ show: 250, hide: 250 }}
                                                overlay={<Tooltip>{copyTooltip}</Tooltip>}
                                            >
                                                <button
                                                    className="fs-9 fw-light text-bg-secondary badge rounded-pill py-1 border-0"
                                                    onClick={() => {
                                                        setCopyTooltip('Copied!');
                                                        copyToClipboard(historyItem.jobId);

                                                        setTimeout(() => {
                                                            setCopyTooltip('Copy to clipboard');
                                                        }, 2000);
                                                    }}
                                                >
                                                    Job ID: {historyItem.jobId}
                                                </button>
                                            </OverlayTrigger>
                                        </div>
                                        {historyItem.taskExecutions !== undefined &&
                                            historyItem.taskExecutions !== null &&
                                            historyItem.taskExecutions.map((execution) => (
                                                <Accordion.Item
                                                    eventKey={`${execution.startedAt.toString()}-${execution.name}`}
                                                    key={`${execution.startedAt.toString()}-${execution.name}`}
                                                >
                                                    <Accordion.Header>
                                                        <Container fluid>
                                                            <div className="d-flex gap-2 text-break align-items-center">
                                                                {execution.status === 'SUCCESS' && (
                                                                    <CheckCircleFillIcon
                                                                        className="text-success"
                                                                        size={24}
                                                                    />
                                                                )}
                                                                {execution.status === 'ERROR' && (
                                                                    <XCircleFillIcon
                                                                        className="text-danger"
                                                                        size={24}
                                                                    />
                                                                )}
                                                                {execution.status === 'RUNNING' && (
                                                                    <IssueReopenedIcon
                                                                        className="text-secondary rotate"
                                                                        size={24}
                                                                        fill='#7444FF'
                                                                    />
                                                                )}
                                                                {execution.status === 'PENDING' && (
                                                                    <HistoryIcon
                                                                        className="text-secondary"
                                                                        size={24}
                                                                    />
                                                                )}
                                                                {execution.status !== 'SUCCESS' &&
                                                                    execution.status !== 'ERROR' &&
                                                                    execution.status !== 'RUNNING' &&
                                                                    execution.status !== 'PENDING' && (
                                                                        <AlertFillIcon
                                                                            className="text-secondary"
                                                                            size={24}
                                                                        />
                                                                    )}
                                                                {execution.name}
                                                            </div>
                                                        </Container>
                                                    </Accordion.Header>
                                                    <Accordion.Body>
                                                        <Container>
                                                            <div className="d-flex table-responsive">
                                                                <table className="table table-hover">
                                                                    <tbody>
                                                                        {execution.history.map((execHistory) => (
                                                                            <tr
                                                                                key={`${execHistory.status}-${execHistory.timestamp}`}
                                                                            >
                                                                                <td>
                                                                                    {execHistory.status ===
                                                                                        'PENDING' && (
                                                                                            <HistoryIcon
                                                                                                className="text-secondary"
                                                                                                size={24}
                                                                                            />
                                                                                        )}
                                                                                    {execHistory.status ===
                                                                                        'STARTED' && (
                                                                                            <AlertFillIcon
                                                                                                className="text-secondary"
                                                                                                size={24}
                                                                                            />
                                                                                        )}
                                                                                    {execHistory.status ===
                                                                                        'SUCCESS' && (
                                                                                            <CheckCircleFillIcon
                                                                                                className="text-success"
                                                                                                size={24}
                                                                                            />
                                                                                        )}
                                                                                    {execHistory.status === 'ERROR' && (
                                                                                        <XCircleFillIcon
                                                                                            className="text-danger"
                                                                                            size={24}
                                                                                        />
                                                                                    )}
                                                                                </td>
                                                                                <td>{execHistory.status}</td>
                                                                                <td>
                                                                                    <span className="fw-bold">
                                                                                        Timestamp:{' '}
                                                                                    </span>
                                                                                    {timestampToDate(
                                                                                        execHistory.timestamp,
                                                                                    )}
                                                                                </td>
                                                                            </tr>
                                                                        ))}
                                                                    </tbody>
                                                                </table>
                                                            </div>
                                                            {Object.keys(execution.stats).length > 0 &&
                                                                execution.stats.length > 0 && execution.stats[execution.stats.length - 1] !== null && (
                                                                    <LiveStats
                                                                        liveStats={
                                                                            execution.stats[execution.stats.length - 1]
                                                                        }
                                                                        type={execution.type}
                                                                        xs={12}
                                                                        sm={12}
                                                                        md={12}
                                                                    />
                                                                )}
                                                            {/* {execution.history.map((execHistory) => (
                                                                <div className="d-flex justify-content-end align-items-center gap-2">
                                                                    {execHistory.status === 'SUCCESS' && execution.type === 'crawl' && (
                                                                    <td>
                                                                        <button
                                                                            className="btn btn-sm btn-primary"
                                                                            onClick={() => {
                                                                                downloadFileChunk(historyItem.workflowName, historyItem.jobId, execution.name);
                                                                            }}
                                                                        >
                                                                            Download feed
                                                                        </button>
                                                                    </td>
                                                                )}
                                                                </div>
                                                            ))} */}
                                                        </Container>
                                                    </Accordion.Body>
                                                </Accordion.Item>
                                            ))}
                                    </Accordion>
                                </Accordion.Body>
                            </Accordion.Item>
                        ))}
                    </Accordion>
                )}
            </Offcanvas.Body>
        </Offcanvas>
    );
}

export default CompletedOffcanvas;
