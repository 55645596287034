import { Offcanvas, Accordion, Container, Spinner } from 'react-bootstrap';
import {
    CheckCircleFillIcon,
    CloudOfflineIcon,
    XCircleFillIcon,
    AlertFillIcon,
    ClockFillIcon,
    CheckCircleIcon,
    XCircleIcon,
} from '@primer/octicons-react';
import { Job } from '../../services/jobs.service';
import { timestampToDate, timestampToShortDate, isToday, isTomorrow, isNotNear } from '../../services/date.service';

function UpcomingOffcanvas(props: any) {
    return (
        <Offcanvas show={props.upcomingShow} onHide={props.handleUpcomingToggle} placement={'end'}>
            <Offcanvas.Header closeButton>
                <Offcanvas.Title>Upcoming Jobs</Offcanvas.Title>
            </Offcanvas.Header>
            <Offcanvas.Body>
                {props.summary === null && (
                    <div className="d-flex justify-content-center gap-4 py-4 opacity-75">
                        <Spinner animation="grow" variant="primary" size="sm" />
                        <Spinner animation="grow" variant="primary" size="sm" />
                        <Spinner animation="grow" variant="primary" size="sm" />
                    </div>
                )}
                {props.summary !== null && props.summary.upcoming.length === 0 && (
                    <div className="d-flex justify-content-center gap-2">
                        <CloudOfflineIcon size={20} />
                        No Records Found
                    </div>
                )}
                {props.summary !== null && props.summary.upcoming.length > 0 && (
                    <Accordion flush>
                        {props.summary.upcoming.map((job: Job) => (
                            <Accordion.Item eventKey={`${job.name}`} key={job.name}>
                                <Accordion.Header>
                                    <div className="d-flex gap-2 text-break align-items-center">
                                        {/* {job['state']?<CheckCircleIcon className='text-main' size={18} />:<XCircleIcon className='text-gray' size={18} />} */}
                                        <span className={`badge rounded-pill text-bg-primary`}>
                                            {timestampToDate(job.nextRun)}
                                        </span>
                                        {job.name}
                                    </div>
                                </Accordion.Header>
                                <Accordion.Body>
                                    <div className="table-responsive">
                                        <table className="table">
                                            <tbody>
                                                <tr>
                                                    <th scope="row">Schedule</th>
                                                    <td>{job.schedule}</td>
                                                </tr>
                                                <tr>
                                                    <th scope="row">Next Run</th>
                                                    <td>{timestampToDate(job.nextRun)}</td>
                                                </tr>
                                                <tr>
                                                    <th scope="row">Last Run</th>
                                                    <td>{timestampToDate(job.lastRun)}</td>
                                                </tr>
                                                <tr>
                                                    <th scope="row">Description</th>
                                                    <td>{job.description}</td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </Accordion.Body>
                            </Accordion.Item>
                        ))}
                    </Accordion>
                )}
            </Offcanvas.Body>
        </Offcanvas>
    );
}

export default UpcomingOffcanvas;
