import React, { useState, useEffect } from 'react';
import './Scheduled.css';

import { Table, Container, Form, Button, Fade, Spinner, Accordion, Modal, Toast, ToastContainer } from 'react-bootstrap';
import {
    StopwatchIcon,
    PencilIcon,
    PlusCircleIcon,
    FilterIcon,
    CheckCircleIcon,
    XCircleIcon,
    SortDescIcon,
    TrashIcon,
} from '@primer/octicons-react';
import DatePicker from 'react-date-picker/dist/entry.nostyle';

import EditJob from '../../../Components/Modals/EditJob';
import AddJob from '../../../Components/Modals/AddJob';
import ViewHistory from '../../../Components/Modals/ViewHistory';

import { Job, getJobs, updateJobBackend, deleteScheduledJob, getTaskDefinitions } from '../../../services/jobs.service';
import {
    timestampToDate,
    timestampToShortDate,
    dateToTimestamp,
    isToday,
    isTomorrow,
    isNotNear,
} from '../../../services/date.service';

// TODO: FIX THE SORTING ENABLE DISABLE BUTTON
// TODO: FIX THE RESPONSIVENESS

function Scheduled(props: any) {
    document.title = `Scheduled Workflows` + process.env.REACT_APP_TITLE_POSTFIX;

    const [jobs, setJobs] = useState<Job[]>([]);
    const [jobToEdit, setJobToEdit] = useState<Job | null>(null);
    const [jobToHistory, setJobToHistory] = useState<Job | null>(null);

    const [showAddModal, setShowAddModal] = useState(false);
    const [showEditModal, setShowEditModal] = useState(false);
    const [showHistoryModal, setShowHistoryModal] = useState(false);

    const [cancelHandler, setCancelHandler] = useState<AbortController | null>(null);

    const [isActive, setIsActive] = useState<any>(undefined);
    const [nextRun, setNextRun] = useState<any>(undefined);
    const [lastRun, setLastRun] = useState<any>(undefined);
    const [sortField, setSortField] = useState<string | undefined>('nextRun');
    const [sortOrder, setSortOrder] = useState<string | undefined>('ASC');

    const [enableSorting, setEnableSorting] = useState(true);
    const [enableFilters, setEnableFilters] = useState(false);
    const [updateRequired, setUpdateRequired] = useState(false);
    const [nextRunDate, setNextRunDate] = useState<any>(undefined);
    const [lastRunDate, setLastRunDate] = useState<any>(undefined);

    const [showConfirmationModal, setShowConfirmationModal] = useState(false);
    const [awaiting, setAwaiting] = useState(false);
    const [jobToDelete, setjobToDelete] = useState<string>('');
    const [successMsg, setSuccessMsg] = useState<string | null>(null);
    const [errorMsg, setErrorMsg] = useState<string | null>(null);
    const [showToast, setShowToast] = useState(false);
    const [taskDefinitions, setTaskDefinitions] = useState<any>([]);

    async function updateJob(updatedJob: Job, setError: Function, awaitCallback: Function) {
        // Need to hit API here in future
        if (await updateJobBackend(updatedJob, setError, setCancelHandler)) {
            // Updating local value
            const index = jobs.findIndex((job) => job.workflowKey === updatedJob.workflowKey);
            let tempJobs = jobs;
            tempJobs[index] = updatedJob;

            setJobs(tempJobs);
            setShowEditModal(false);
        }

        awaitCallback(false);
    }

    function handleEditJob(job: Job) {
        setJobToEdit(job);
        setShowEditModal(true);
    }

    function addJob(newJob: Job) {
        // Updating local value
        setJobs([newJob, ...jobs]);

        setShowAddModal(false);
    }

    function handleAddJob() {
        setShowAddModal(true);
    }

    function handleViewHistory(job: Job) {
        setJobToHistory(job);
        setShowHistoryModal(true);
    }

    useEffect(() => {
        props.setActive('scheduled');
        fetchJobs();
        if (taskDefinitions.length === 0) {
            getTaskDefinitions(setCancelHandler).then((res) => {
                setTaskDefinitions(res);
            });
        }
    }, [taskDefinitions]);

    function fetchJobs() {
        getJobs(isActive, nextRun, lastRun, sortField, sortOrder, setCancelHandler).then((res) => {
            // console.log(res);
            setJobs(res);

            setUpdateRequired(false); //Setting it to false because an update was just provided
        });
    }

    function toggleSorting() {
        if (enableSorting === false) {
            //It is currently false, so we set up default values for sorting
            setUpdateRequired(true);
            setSortField('name');
            setSortOrder('ASC');
        } else if (sortField !== undefined) {
            //It is already true, so we need to remove sorting
            setUpdateRequired(true);
            setSortField(undefined);
            setSortOrder(undefined);
        }
        setEnableSorting((prevValue) => {
            return !prevValue;
        });
        console.log('Sorting Toggled');
    }

    function handleSortField(event: any) {
        let value = event.target.value;

        if (value !== sortField) {
            setUpdateRequired(true);
        }

        if (value === 'Name') {
            setSortField('name');
        } else if (value === 'Next Run') {
            setSortField('nextRun');
        }
    }

    function toggleFilters() {
        if (enableFilters && (isActive !== undefined || nextRun || lastRun)) {
            //It is already true, meaning we need to turn it off, so removing filters
            setUpdateRequired(true);
            setIsActive(undefined);
            setNextRun(undefined);
            setLastRun(undefined);
        }
        setEnableFilters((prevValue) => {
            return !prevValue;
        });
    }

    useEffect(() => {
        if (updateRequired) {
            console.log('Some filter has been changed');
            setJobs([]);
            fetchJobs();
        }
    }, [isActive, nextRun, lastRun, sortField, sortOrder]);

    function handleNextRunDate(newDate: Date) {
        if (newDate !== nextRunDate) {
            setUpdateRequired(true);
            if (newDate === null) {
                setNextRunDate(undefined);
                setNextRun(undefined);
            } else {
                // Removing the last run filter if it exists
                setLastRunDate(undefined);
                setLastRun(undefined);

                setNextRunDate(newDate);
                setNextRun(dateToTimestamp(newDate, 1)); // Providing 1 as day offset because we want provided date to be inclusive
            }
        }
    }

    function handleLastRunDate(newDate: Date) {
        if (newDate !== lastRunDate) {
            setUpdateRequired(true);
            if (newDate === null) {
                setLastRunDate(undefined);
                setLastRun(undefined);
            } else {
                // Removing the next run filter if it exists
                setNextRunDate(undefined);
                setNextRun(undefined);

                setLastRunDate(newDate);
                setLastRun(dateToTimestamp(newDate));
            }
        }
    }

    function handleDeleteJob(job: Job) {
        setjobToDelete(job.name);
        setShowConfirmationModal(true);
    }

    function handleCancelDelete() {
        setShowConfirmationModal(false);
        setjobToDelete('');
    }

    async function handleConfirmDelete() {
        setAwaiting(true);
        setSuccessMsg(null);
        setErrorMsg(null);

        await deleteScheduledJob(jobToDelete, setSuccessMsg, setErrorMsg, setCancelHandler);
        
        setAwaiting(false);
        setShowConfirmationModal(false);
        setShowToast(true);
        setjobToDelete('');
    }

    function toggleToastShow() {
        setShowToast((value) => {
            return !value;
        });
    }

    return (
        <>
            <Container fluid className="mt-3 d-flex">
                <Container fluid className="p-0 d-flex flex-wrap justify-content-start align-items-end">
                    <Button className="bg-white text-main border-0 shadow-sm rounded-5 fs-7" onClick={handleAddJob}>
                        <PlusCircleIcon size={18} className="me-2" />
                        Create
                    </Button>
                    <Button className="bg-transparent text-main border-0" onClick={toggleSorting}>
                        <SortDescIcon size={18} /> {enableSorting ? 'Disable' : 'Enable'} Sorting
                    </Button>
                    <Fade in={enableSorting} mountOnEnter={true} unmountOnExit={true}>
                        <Form className="text-main p-1 d-flex">
                            <Form.Select
                                size="sm"
                                className="rounded-4 shadow-sm"
                                defaultValue="Next Run"
                                onChange={handleSortField}
                            >
                                <option>Name</option>
                                <option>Next Run</option>
                            </Form.Select>
                        </Form>
                    </Fade>
                    <Button className="bg-transparent text-main border-0" onClick={toggleFilters}>
                        <FilterIcon size={18} /> {enableFilters ? 'Disable' : 'Enable'} Filters
                    </Button>
                    <Fade in={enableFilters} mountOnEnter={true} unmountOnExit={true}>
                        <Form className="text-main d-flex flex-wrap">
                            <Button
                                onClick={() => {
                                    setUpdateRequired(true);
                                    setIsActive(isActive === undefined ? 1 : isActive === 1 ? 0 : 1);
                                }}
                                className={`m-1 border-0 rounded-5 py-1  fs-7 ${isActive === 1 ? 'bg-main-1 text-white' : 'bg-dim-dark'
                                    }`}
                            >
                                Active Only
                            </Button>
                            <div className="m-1 px-2 shadow-sm text-main bg-white rounded-4">
                                Next Run
                                <DatePicker
                                    onChange={handleNextRunDate}
                                    value={nextRunDate}
                                    dayPlaceholder="dd"
                                    monthPlaceholder="mm"
                                    yearPlaceholder="yyyy"
                                    minDate={new Date()}
                                />
                            </div>
                            <div className="m-1 px-2 shadow-sm text-main bg-white border-0 rounded-4">
                                Last Run
                                <DatePicker
                                    onChange={handleLastRunDate}
                                    value={lastRunDate}
                                    dayPlaceholder="dd"
                                    monthPlaceholder="mm"
                                    yearPlaceholder="yyyy"
                                    maxDate={new Date()}
                                />
                            </div>
                        </Form>
                    </Fade>
                </Container>
            </Container>
            <Container fluid className="bg-white mt-2 rounded-3 shadow-sm pb-2">
                {props.device.isMobile && (
                    <>
                        {jobs.length === 0 && (
                            <div className="d-flex justify-content-center gap-4 py-4 opacity-75">
                                <Spinner animation="grow" variant="primary" size="sm" />
                                <Spinner animation="grow" variant="primary" size="sm" />
                                <Spinner animation="grow" variant="primary" size="sm" />
                            </div>
                        )}
                        {jobs.length > 0 && (
                            <Accordion flush>
                                {jobs.map((job) => (
                                    <Accordion.Item eventKey={`${job.name}`} key={job.name}>
                                        <Accordion.Header>
                                            <div className="d-flex gap-2 text-break align-items-center">
                                                {job['state'] ? (
                                                    <CheckCircleIcon className="text-main" size={18} />
                                                ) : (
                                                    <XCircleIcon className="text-gray" size={18} />
                                                )}
                                                {isToday(job.nextRun) && job.state && (
                                                    <span className={`badge rounded-pill text-bg-primary`}>Today</span>
                                                )}
                                                {isTomorrow(job.nextRun) && job.state && (
                                                    <span className={`badge rounded-pill text-bg-secondary`}>
                                                        Tomorrow
                                                    </span>
                                                )}
                                                {isNotNear(job.nextRun) && (
                                                    <span className={`badge rounded-pill text-bg-secondary`}>
                                                        {timestampToShortDate(job.nextRun)}
                                                    </span>
                                                )}
                                                {job.name}
                                            </div>
                                        </Accordion.Header>
                                        <Accordion.Body>
                                            <div className="table-responsive">
                                                <table className="table">
                                                    <tbody>
                                                        <tr>
                                                            <th scope="row">Schedule</th>
                                                            <td>{job.schedule}</td>
                                                        </tr>
                                                        <tr>
                                                            <th scope="row">Next Run</th>
                                                            <td>{timestampToDate(job.nextRun)}</td>
                                                        </tr>
                                                        <tr>
                                                            <th scope="row">Last Run</th>
                                                            <td>{timestampToDate(job.lastRun)}</td>
                                                        </tr>
                                                        <tr>
                                                            <th scope="row">Description</th>
                                                            <td>{job.description}</td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </div>
                                            <div className="d-flex gap-2 justify-content-end">
                                                <Button
                                                    className={`bg-primary border-0 rounded-5 mt-3`}
                                                    onClick={() => {
                                                        handleEditJob(job);
                                                    }}
                                                >
                                                    Edit Job
                                                </Button>
                                                <Button
                                                    className={`bg-primary border-0 rounded-5 mt-3`}
                                                    onClick={() => {
                                                        handleViewHistory(job);
                                                    }}
                                                >
                                                    View History
                                                </Button>
                                            </div>
                                        </Accordion.Body>
                                    </Accordion.Item>
                                ))}
                            </Accordion>
                        )}
                    </>
                )}
                {props.device.isMobile === false && (
                    <Table hover className="my-3 fs-7">
                        <thead className="border-bottom">
                            <tr>
                                <th className="fw-heavy text-gray">Name</th>
                                <th className="fw-heavy text-gray">Schedule</th>
                                <th className="fw-heavy text-gray">Active</th>
                                <th className="fw-heavy text-gray">Next Run</th>
                                <th className="fw-heavy text-gray">Last Run</th>
                                <th className="fw-heavy text-gray"></th>
                                <th className="fw-heavy text-gray"></th>
                                <th className="fw-heavy text-gray"></th>
                            </tr>
                        </thead>
                        <tbody>
                            {jobs.length === 0 &&
                                ['100', '75', '50', '25'].map((opacity) => (
                                    <tr key={opacity}>
                                        <td>
                                            <div className={`bg-light text-light opacity-${opacity}`}>1</div>
                                        </td>
                                        <td>
                                            <div className={`bg-light text-light opacity-${opacity}`}>1</div>
                                        </td>
                                        <td>
                                            <div className={`bg-light text-light opacity-${opacity}`}>1</div>
                                        </td>
                                        <td>
                                            <div className={`bg-light text-light opacity-${opacity}`}>1</div>
                                        </td>
                                        <td>
                                            <div className={`bg-light text-light opacity-${opacity}`}>1</div>
                                        </td>
                                        <td>
                                            <div className={`bg-light text-light opacity-${opacity}`}>1</div>
                                        </td>
                                        <td>
                                            <div className={`bg-light text-light opacity-${opacity}`}>1</div>
                                        </td>
                                        <td>
                                            <div className={`bg-light text-light opacity-${opacity}`}>1</div>
                                        </td>
                                    </tr>
                                ))}
                            {jobs.length > 0 &&
                                jobs.map((job) => (
                                    <tr key={job.name}>
                                        <td>{job.name}</td>
                                        <td>{job.schedule}</td>
                                        <td>
                                            {job.state ? (
                                                <CheckCircleIcon className="text-main" size={18} />
                                            ) : (
                                                <XCircleIcon className="text-gray" size={18} />
                                            )}
                                        </td>
                                        <td>{timestampToDate(job.nextRun)}</td>
                                        <td>{timestampToDate(job.lastRun)}</td>
                                        <td>
                                            <Button
                                                className="bg-transparent text-main border-0 p-0"
                                                onClick={() => {
                                                    handleEditJob(job);
                                                }}
                                            >
                                                <PencilIcon size={18} />
                                            </Button>
                                        </td>
                                        <td>
                                            <Button
                                                className="bg-transparent text-main border-0 p-0"
                                                onClick={() => {
                                                    handleDeleteJob(job);
                                                }}
                                            >
                                                <TrashIcon size={18} />
                                            </Button>
                                        </td>
                                        <td>
                                            <Button
                                                className="bg-transparent text-main border-0 p-0"
                                                onClick={() => {
                                                    handleViewHistory(job);
                                                }}
                                            >
                                                <StopwatchIcon size={18} />
                                            </Button>
                                        </td>
                                    </tr>
                                ))}
                        </tbody>
                    </Table>
                )}
            </Container>

            <EditJob job={jobToEdit} taskDefinitions={taskDefinitions} updateJob={updateJob} show={showEditModal} setShow={setShowEditModal} />
            <AddJob
                callback={() => {
                    setJobs([]);
                    fetchJobs();
                }}
                show={showAddModal}
                setShow={setShowAddModal}
            />
            <ViewHistory job={jobToHistory} show={showHistoryModal} setShow={setShowHistoryModal} />
            {showConfirmationModal && (
                <Modal show={showConfirmationModal} onHide={handleCancelDelete}>
                    <Modal.Header closeButton>
                        <Modal.Title>Confirm Delete Scheduled Job</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>Are you sure you want to delete this scheduled job?</Modal.Body>
                    <Modal.Footer>
                        <Button className="bg-secondary rounded-0 border-0 modal-button-width" onClick={handleCancelDelete}>
                            Cancel
                        </Button>
                        <Button
                            onClick={handleConfirmDelete}
                            className={`bg-${awaiting ? 'secondary' : 'danger'
                                } rounded-0 border-0 modal-button-width`}
                        >
                            {awaiting && (
                                <Spinner animation="border" className="w-10" size="sm" />
                            )}
                            {!awaiting && <>Delete</>}
                        </Button>
                    </Modal.Footer>
                </Modal>
            )}
            <ToastContainer containerPosition="fixed" className="m-4" position="top-center">
                <Toast
                    show={showToast}
                    onClose={toggleToastShow}
                    bg={`${successMsg !== null ? 'success' : 'danger'}`}
                    autohide
                >
                    <Toast.Header>
                        <strong className="me-auto">
                            {successMsg !== null && <>{successMsg}</>}
                            {errorMsg !== null && <>{errorMsg}</>}
                        </strong>
                    </Toast.Header>
                </Toast>

            </ToastContainer>
        </>
    );
}

export default Scheduled;
