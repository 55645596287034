import './Sidebar.css';

import { Fade } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { HomeFillIcon, ServerIcon, GitBranchIcon, DesktopDownloadIcon } from '@primer/octicons-react';

function Sidebar(props: any) {
    return (
        <div className={`d-flex flex-${props.device.isMobile ? 'row justify-content-center' : 'column'} mt-2 p-2`}>
            {props.pages.map((arr: string[]) => (
                <Link
                    to={`/dashboard/${arr[1]}`}
                    className={`btn text-start text-nowrap mb-1 py-2 ${
                        props.selected === arr[1]
                            ? 'bg-white rounded-3 shadow-sm  text-dark'
                            : 'bg-transparent text-gray'
                    } fw-medium ${props.isCollapsed ? 'sidebar-button-collapsed' : ''}`}
                    key={arr[1]}
                >
                    <span className={`${props.selected === arr[1] ? 'text-main' : ''}`}>
                        {arr[1] === 'home' && <HomeFillIcon size={20} />}
                        {arr[1] === 'scheduled' && <ServerIcon size={20} />}
                        {arr[1] === 'workflows' && <GitBranchIcon size={20} />}
                        {arr[1] === 'extractors' && <DesktopDownloadIcon size={20} />}
                    </span>
                    <Fade in={!props.isCollapsed} mountOnEnter={true} unmountOnExit={true}>
                        <span className="mx-2">{arr[0]}</span>
                    </Fade>
                </Link>
            ))}
        </div>
    );
}

export default Sidebar;
