import React, { useEffect, useState } from 'react';
import './SignIn.css';

import { Navigate } from 'react-router-dom';
import { Container, Form, Button, Spinner, FloatingLabel } from 'react-bootstrap';

import { adminLogin, googleAuth } from '../../services/auth.service';
import { isValidEmail, isValidPassword } from '../../services/input.service';

function SignIn(props: any) {
    document.title = `Login` + process.env.REACT_APP_TITLE_POSTFIX;

    const [data, setData] = useState({
        email: '',
        password: '',
    });

    const [awaiting, setAwaiting] = useState(false);
    const [dataValid, setDataValid] = useState(false);
    const [curError, setCurError] = useState<string | null>(null);

    // Checking if the entered values pass input validation
    useEffect(() => {
        setDataValid(isValidEmail(data.email) && isValidPassword(data.password));
    }, [data]);

    function handleDataChange(event: any) {
        const value = event.target.type === 'checkbox' ? event.target.checked : event.target.value;
        const name = event.target.name;

        setData({
            ...data,
            [name]: value,
        });
    }

    async function handleSubmit() {
        setCurError(null);

        if (dataValid) {
            setAwaiting(true);
            await adminLogin(data.email, data.password, setCurError);
            setAwaiting(false);
        }
    }

    // Initializing the Google Sign In SDK
    useEffect(() => {
        // Check if the script has already been added to the document or not
        // Also check if div with id googleSignIn has children or not
        if (
            document.getElementById('googleSignInScript') !== null &&
            document.getElementById('googleSignIn')?.childNodes.length !== 0
        )
            return;

        var script = document.createElement('script');
        script.onload = function () {
            let google = (window as any).google;

            google!.accounts.id.initialize({
                client_id: process.env.REACT_APP_GOOGLE_CLIENT_ID,
                callback: handleGoogleSignIn,
            });

            google!.accounts.id.renderButton(document.getElementById('googleSignIn'), {
                theme: 'filled_blue',
                size: 'large',
                // width: 400,
                height: 40,
                longtitle: true,
                type: 'standard',
                shape: 'pill',
                text: 'signin_with',
                logo_alignment: 'left',
            });

            // Display prompt as well
            google!.accounts.id.prompt();

            console.log('Google Sign In SDK Loaded');
        };
        script.src = 'https://accounts.google.com/gsi/client';
        script.async = true;
        script.defer = true;
        script.id = 'googleSignInScript';
        document.head.appendChild(script);
    }, []);

    async function handleGoogleSignIn(response: any) {
        setCurError(null);
        setAwaiting(true);
        await googleAuth(response.credential, setCurError);
        setAwaiting(false);
    }

    return (
        <Container fluid>
            {/* CHECKING IF THE USER IS ALREADY LOGGED IN */}
            {localStorage.getItem('token') !== null && <Navigate to="/dashboard/home" />}
            {/* USER NEEDS TO LOGIN */}
            {localStorage.getItem('token') === null && (
                <div className="row">
                    <div className="bg-overlay"></div>
                    {/* <div id="g_id_onload"
                        data-client_id={process.env.REACT_APP_GOOGLE_CLIENT_ID}
                        data-context="signin"
                        data-ux_mode="popup"
                        data-callback={handleGoogleSignIn}
                        data-itp_support="true">
                    </div> */}
                    <div
                        className={`bg-white py-4 px-4 center-screen d-flex flex-column gap-3 ${
                            props.device.isMobile ? 'col-12 h-100' : 'signin-box rounded-3 shadow-sm'
                        }`}
                    >
                        <div className="d-flex justify-content-center">
                            <img src="logo100.png" alt="crawlnow logo" />
                        </div>
                        <FloatingLabel controlId="form.Email" label="Email address">
                            <Form.Control
                                name="email"
                                type="email"
                                onChange={handleDataChange}
                                placeholder="Email address"
                            />
                        </FloatingLabel>
                        <FloatingLabel controlId="form.Password" label="Password">
                            <Form.Control
                                name="password"
                                type="password"
                                placeholder="Password"
                                onChange={handleDataChange}
                            />
                        </FloatingLabel>
                        {curError !== null && <div className="text-danger">{curError}</div>}

                        {/* <div className="g_id_signin"
                            data-type="standard"
                            data-shape="pill"
                            data-theme="filled_blue"
                            data-text="signin_with"
                            data-size="large"
                            data-logo_alignment="left"
                            data-width="400">
                        </div> */}
                        <div className="d-flex gap-3">
                            <div id="googleSignIn"></div>
                            <Button
                                className={`bg-${awaiting ? 'secondary disabled' : 'main-1'} ${
                                    dataValid ? '' : 'disabled'
                                } border-0 w-100 rounded-5 signin-button-height`}
                                onClick={handleSubmit}
                            >
                                {awaiting && <Spinner animation="border" className="w-10" size="sm" />}
                                {!awaiting && <>Sign In</>}
                            </Button>
                        </div>
                    </div>
                </div>
            )}
        </Container>
    );
}

export default SignIn;
