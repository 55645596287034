import { RepoDeletedIcon } from '@primer/octicons-react';
import { ExtractorVariable } from '../../services/jobs.service';

function ExtractorVariables(props: { variables: ExtractorVariable[] | undefined }) {
    return (
        <>
            {(props.variables === undefined || props.variables.length === 0) && (
                <div className="d-flex justify-content-center align-item-center gap-3 py-4 opacity-75">
                    <RepoDeletedIcon size={24} />
                    Extractor has no variables!
                </div>
            )}
            {props.variables !== undefined && props.variables.length > 0 && (
                <div className="table-responsive">
                    <table className="table">
                        <tbody>
                            <tr>
                                <th scope="row">Name</th>
                                <th scope="row">Required</th>
                                <th scope="row">Example</th>
                                <th scope="row">Description</th>
                            </tr>
                            {props.variables.map((variable) => (
                                <tr key={variable.name}>
                                    <td>{variable.name}</td>
                                    <td>{String(variable.required)}</td>
                                    <td>{variable.example ? String(variable.example) : ''}</td>
                                    <td>{variable.description ? String(variable.description) : ''}</td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
            )}
        </>
    );
}

export default ExtractorVariables;
