import React, { useEffect, useState } from 'react';
import './App.css';
import { Routes, Route, BrowserRouter } from 'react-router-dom';
import SignIn from './pages/SignIn/SignIn';

// Custom Components
import Dashboard from './pages/Dashboard/Dashboard';
import Home from './pages/Dashboard/Home/Home';
import Scheduled from './pages/Dashboard/Scheduled/Scheduled';
import Workflows from './pages/Dashboard/Workflows/Workflows';
import Extractors from './pages/Dashboard/Extractors/Extractors';

const breakpoints = {
    sm: 900,
    md: 1300,
};

function App() {
    // The first calculation, only for initializing states
    const fWidth = document.documentElement.clientWidth;

    const [vpWidth, setVPWidth] = useState(document.documentElement.clientWidth);
    const [vpHeight, setVPHeight] = useState(document.documentElement.clientHeight);

    const [selected, setSelected] = useState('home');

    const [isMobile, setIsMobile] = useState(fWidth < breakpoints.sm);
    const [isTablet, setIsTablet] = useState(fWidth >= breakpoints.sm && fWidth < breakpoints.md);
    const [isDesktop, setIsDesktop] = useState(fWidth >= breakpoints.md);

    function displayWindowSize() {
        // Get width and height of the window excluding scrollbars
        var w = document.documentElement.clientWidth;
        var h = document.documentElement.clientHeight;

        setVPWidth(w);
        setVPHeight(h);

        setIsMobile(w < breakpoints.sm);
        setIsTablet(w >= breakpoints.sm && w < breakpoints.md);
        setIsDesktop(w >= breakpoints.md);
    }

    useEffect(() => {
        window.addEventListener('resize', displayWindowSize);
    }, []);

    return (
        <div className="App">
            <BrowserRouter>
                <Routes>
                    <Route
                        path="dashboard"
                        element={
                            <Dashboard
                                breakpoints={breakpoints}
                                viewport={{ width: vpWidth, height: vpHeight }}
                                device={{ isMobile: isMobile, isTablet: isTablet, isDesktop: isDesktop }}
                                selected={selected}
                            />
                        }
                    >
                        <Route
                            path="home"
                            element={
                                <Home
                                    setActive={setSelected}
                                    device={{ isMobile: isMobile, isTablet: isTablet, isDesktop: isDesktop }}
                                />
                            }
                        />
                        <Route
                            path="scheduled"
                            element={
                                <Scheduled
                                    setActive={setSelected}
                                    device={{ isMobile: isMobile, isTablet: isTablet, isDesktop: isDesktop }}
                                />
                            }
                        />
                        <Route
                            path="workflows"
                            element={
                                <Workflows
                                    setActive={setSelected}
                                    device={{ isMobile: isMobile, isTablet: isTablet, isDesktop: isDesktop }}
                                />
                            }
                        />
                        <Route
                            path="extractors/*"
                            element={
                                <Extractors
                                    setActive={setSelected}
                                    device={{ isMobile: isMobile, isTablet: isTablet, isDesktop: isDesktop }}
                                />
                            }
                        />
                    </Route>
                    <Route
                        path="/"
                        element={<SignIn device={{ isMobile: isMobile, isTablet: isTablet, isDesktop: isDesktop }} />}
                    />
                </Routes>
            </BrowserRouter>
        </div>
    );
}

export default App;
