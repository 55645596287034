import './TopNavbar.css';

import { Navbar, Container, Button, Toast, ToastContainer, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { BeakerIcon, PersonFillIcon, SignOutIcon, ThreeBarsIcon } from '@primer/octicons-react';
import { useState } from 'react';

function TopNavBar(props: any) {
    const [show, setShow] = useState(false);

    function copyToClipboard() {
        // Get token from local storage
        let token = localStorage.getItem('token');

        // Copy token to clipboard
        navigator.clipboard.writeText(token as string);

        // Show toast
        setShow(true);
    }

    return (
        <Navbar className="navbar navbar-custom shadow bg-primary-dark" variant="dark" fixed="top">
            <ToastContainer className="p-3" position={'top-center'}>
                <Toast onClose={() => setShow(false)} show={show} delay={6000} autohide>
                    <Toast.Header>
                        <BeakerIcon size={16} />
                        <strong className="me-auto ms-2">Profiles are not implemented yet</strong>
                        <small>Just now</small>
                    </Toast.Header>
                    <Toast.Body>
                        Clicking on this button copies the JWT token to clipboard. Use it carefully and do not share it
                        with anyone.
                    </Toast.Body>
                </Toast>
            </ToastContainer>
            <Container fluid className="justify-content-between">
                <Navbar.Collapse>
                    <Button
                        className="bg-transparent border-0 p-0 d-sm-none d-md-block"
                        onClick={props.collapseControl}
                    >
                        <ThreeBarsIcon size={16} />
                    </Button>
                </Navbar.Collapse>
                <Navbar.Collapse className="d-flex justify-content-end">
                    <OverlayTrigger
                        placement="bottom"
                        delay={{ show: 1000, hide: 0 }}
                        overlay={<Tooltip id="tooltip-disabled">Copy token to clipboard</Tooltip>}
                    >
                        <Button
                            className="bg-transparent border-0"
                            onClick={copyToClipboard}
                            data-bs-toggle="tooltip"
                            data-bs-placement="bottom"
                            data-bs-title="Tooltip on bottom"
                        >
                            <Navbar.Text className="fs-8">
                                {props.name} <PersonFillIcon size={16} />
                            </Navbar.Text>
                        </Button>
                    </OverlayTrigger>
                    <Button className="bg-transparent border-0" onClick={props.signOut}>
                        <Navbar.Text className="fs-8">
                            Sign Out <SignOutIcon size={16} />
                        </Navbar.Text>
                    </Button>
                </Navbar.Collapse>
            </Container>
        </Navbar>
    );
}

export default TopNavBar;
