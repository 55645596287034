import React, { useEffect } from 'react';
import './Home.css';

import HeatMap from '../../../Components/Charts/Test';
import Summary from '../../../Components/Summary/Summary';

function Home(props: any) {
    document.title = `Home` + process.env.REACT_APP_TITLE_POSTFIX;

    useEffect(() => {
        props.setActive('home');
    }, []);

    return (
        <div className="mt-3">
            <Summary />
            {/* <HeatMap /> */}
        </div>
    );
}

export default Home;
