import './UploadFile.css';
import { useState, useEffect, useCallback } from 'react';
import { Modal, Button, Nav, Tab, Row, Col, Ratio, Form, Spinner } from 'react-bootstrap';
import { FileIcon, KebabHorizontalIcon, CloudOfflineIcon } from '@primer/octicons-react';
import { getExtractorInputFiles, FileDetails, uploadExtractorInputFile } from '../../services/jobs.service';
import { useDropzone } from 'react-dropzone';

function UploadFile(props: any) {
    const tabNames = ['Select', 'Upload'];
    const handleClose = () => props.setShow(false);
    const [awaiting, setAwaiting] = useState(false);
    const [curError, setCurError] = useState<string | null>(null);
    const [cancelHandler1, setCancelHandler1] = useState<AbortController | null>(null);
    const [cancelHandler2, setCancelHandler2] = useState<AbortController | null>(null);
    const [selectedIndex, setSelectedIndex] = useState(null);
    const [focusedIndex, setFocusedIndex] = useState<any>(null);
    const [selectedFileName, setSelectedFileName] = useState<string | null>(null);
    const [selectedFile, setSelectedFile] = useState<File | null>(null);
    const [selectedTab, setSelectedTab] = useState<string>(tabNames[0]);
    const [files, setFiles] = useState<FileDetails[] | null>(null);
    const [hoveredIndex, setHoveredIndex] = useState<number | null>(null);

    const { getRootProps, getInputProps, isDragActive } = useDropzone({
        onDrop: useCallback((acceptedFiles: File[]) => {
            console.log('Uploaded files', acceptedFiles[0]);
            setSelectedFile(acceptedFiles[0]);
        }, []),
        maxFiles: 1, // Allow only one file to be uploaded
    });

    async function fetchData() {
        try {
            const data = await getExtractorInputFiles(props.selectedKey, setCurError, setCancelHandler1);
            setFiles(data);
        } catch (error) {
            console.error('Error fetching file data', error);
        }
    }

    useEffect(() => {
        if (props.show) {
            // Setting all the state variables to default values
            setFiles(null);
            setSelectedIndex(null);
            setFocusedIndex(null);
            setSelectedFileName(null);
            setSelectedFile(null);
            setCurError(null);
            setSelectedTab(tabNames[0]);
            props.onFileSelection(undefined, props.inputVariableName);
            fetchData();
        }
    }, [props.show]);

    function handleSelect(eventKey: string | null) {
        if (eventKey !== null) {
            setSelectedTab(eventKey);
        }
    }

    function handleThreeDotClick(file: string) {
        console.log(`Three dot button clicked :`, file);
    }

    function extractFilenameFromPath(path: string) {
        const pathSegments = path.split('/');
        const filename = pathSegments[pathSegments.length - 1];
        return filename;
    }

    async function handleSelectButtonClick() {
        if (selectedTab == tabNames[0]) {
            // selected tab
            props.onFileSelection(selectedFileName, props.inputVariableName);
            props.setShow(false);
            console.log('selected ', selectedFileName);
        }

        if (selectedTab == tabNames[1] && selectedFile !== null) {
            // upload tab
            setCurError(null);
            setAwaiting(true);
            const result = await uploadExtractorInputFile(
                props.selectedKey,
                selectedFile,
                setCurError,
                setCancelHandler2,
            );
            setAwaiting(false);
            if (result !== null) {
                setSelectedFileName(result);
                props.onFileSelection(result, props.inputVariableName);
                props.setShow(false);
            }
        }
        // console.log(selectedFileName, "selected file");
    }

    function handleFileSelection(index: any, key: string) {
        if (selectedIndex === index) {
            setSelectedIndex(null);
        } else {
            setSelectedIndex(index);
        }
        setFocusedIndex(index);
        setHoveredIndex(null);
        setSelectedFileName(extractFilenameFromPath(key));
    }

    return (
        <>
            <Modal show={props.show} onHide={props.handleClose} centered size="xl">
                <Modal.Body>
                    <Tab.Container activeKey={selectedTab} onSelect={handleSelect}>
                        <Nav variant="tabs">
                            {tabNames.map((tab) => (
                                <Nav.Item key={tab}>
                                    <Nav.Link eventKey={tab}>{tab}</Nav.Link>
                                </Nav.Item>
                            ))}
                        </Nav>
                        <Tab.Content>
                            <Tab.Pane eventKey="Select" className="m-4">
                                {files === null && (
                                    // If files are not loaded yet, show a loading indicator
                                    <div className="text-center">
                                        <Spinner animation="border" role="status"></Spinner>
                                    </div>
                                )}
                                {files !== null && files.length === 0 && (
                                    // If files are not loaded yet, show a loading indicator
                                    <div className="text-center">
                                        <CloudOfflineIcon size={20} /> No Files Found
                                    </div>
                                )}
                                {files !== null && files.length > 0 && (
                                    <Row xs={2} sm={3} md={4}>
                                        {files.map((file, index) => (
                                            <Col
                                                key={index}
                                                className="mb-3"
                                                onMouseEnter={() => setHoveredIndex(index)}
                                                onMouseLeave={() => setHoveredIndex(null)}
                                            >
                                                <Ratio aspectRatio={2 / 1}>
                                                    <div
                                                        key={index}
                                                        className={`text-center p-2 position-relative rounded-3 main-div ${
                                                            selectedIndex === index ? 'selected' : ''
                                                        } ${focusedIndex === index ? 'focused' : ''}`}
                                                        onClick={() => handleFileSelection(index, file.Key)}
                                                        onMouseEnter={() => setHoveredIndex(index)}
                                                        onMouseLeave={() => setHoveredIndex(null)}
                                                        onFocus={() => setFocusedIndex(index)}
                                                        onBlur={() => setFocusedIndex(null)}
                                                        tabIndex={0}
                                                    >
                                                        <div className="d-flex align-items-center fs-16 py-2">
                                                            <div className="ps-2 pb-2">
                                                                <FileIcon size={15} />{' '}
                                                            </div>
                                                            <div className="flex-grow-1 font-weight-bold text-truncate mx-4">
                                                                {extractFilenameFromPath(file.Key)}
                                                            </div>
                                                            <div
                                                                className="three-dot-icon position-absolute top-1 end-0 pe-2 cursor-pointer"
                                                                onClick={() => handleThreeDotClick(file.Key)}
                                                            >
                                                                <KebabHorizontalIcon size={15} />
                                                            </div>
                                                        </div>
                                                        <div className="bg-white p-5 rounded-3">
                                                            <FileIcon size={60} />
                                                        </div>
                                                    </div>
                                                </Ratio>
                                            </Col>
                                        ))}
                                    </Row>
                                )}
                            </Tab.Pane>
                            <Tab.Pane eventKey="Upload">
                                <div className="d-flex flex-column align-items-center  my-5 mx-auto p-4 ">
                                    <div
                                        {...getRootProps()}
                                        className={`dropzone rounded text-center w-50 h-75 ${
                                            isDragActive ? 'active' : ''
                                        }`}
                                    >
                                        <input {...getInputProps()} />
                                        {isDragActive ? (
                                            <p className="mt-3 text-truncate">Drop the file here...</p>
                                        ) : (
                                            <p className="mt-3 text-truncate">
                                                {selectedFile !== null ? (
                                                    <>
                                                        <FileIcon size={13} className="mb-1" /> {selectedFile.name}
                                                    </>
                                                ) : (
                                                    'Drag & Drop or click to select a file'
                                                )}
                                            </p>
                                        )}
                                    </div>
                                </div>
                            </Tab.Pane>
                        </Tab.Content>
                    </Tab.Container>
                </Modal.Body>
                <Modal.Footer>
                    {curError !== null && <p className="text-danger">{curError}</p>}

                    <Button onClick={handleClose} className={`bg-transparent text-dark rounded-0 border-0`}>
                        Close
                    </Button>
                    <Button
                        className={`bg-${awaiting ? 'secondary disabled' : 'main-1 '} ${
                            (selectedTab === tabNames[0] && selectedFileName !== null) ||
                            (selectedTab === tabNames[1] && selectedFile !== null)
                                ? ''
                                : 'disabled'
                        } rounded-0 border-0 modal-button-width`}
                        onClick={handleSelectButtonClick}
                    >
                        {awaiting && <Spinner animation="border" className="w-10" size="sm" />}
                        {!awaiting && <>{selectedTab}</>}
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    );
}

export default UploadFile;
