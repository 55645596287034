import React, { useEffect, useState } from 'react';
import './Dashboard.css';

import { Container, Row, Col, Spinner } from 'react-bootstrap';
import { Outlet, Navigate } from 'react-router-dom';

import TopNavBar from '../../Components/Navbars/TopNavbar/TopNavbar';
import BotNavbar from '../../Components/Navbars/BotNavbar/BotNavbar';
import Sidebar from '../../Components/Navbars/Sidebar/Sidebar';

import { User, tokenCheck, emptyUser } from '../../services/auth.service';

function Dashboard(props: any) {
    let pages = [
        ['Dashboard', 'home'],
        ['Scheduled Workflows', 'scheduled'],
        ['Workflows', 'workflows'],
        ['Extractors', 'extractors'],
    ];

    // Looking for the auth token
    const [AUTH_TOKEN, SET_AUTH_TOKEN] = useState<string | null>(localStorage.getItem('token'));
    const [userData, setUserData] = useState<User>(emptyUser);
    const [isAuthorized, setIsAuthorized] = useState<boolean>(false);

    const authTimeout = 5 * 60 * 1000; // 5 Minutes

    function signOut() {
        localStorage.removeItem('token');
        SET_AUTH_TOKEN(null);
        setIsAuthorized(false);
    }

    async function verifyCheck() {
        let tempData: User = await tokenCheck(AUTH_TOKEN);

        if (tempData.isActive) {
            setIsAuthorized(true);
        } else {
            localStorage.removeItem('token');
            SET_AUTH_TOKEN(null);
        }
    }

    const [collapseSidebar, setCollapseSidebar] = useState(
        props.viewport.width >= props.breakpoints.sm && props.viewport.width < props.breakpoints.md,
    );
    const [userCollapseSidebar, setUserCollapseSidebar] = useState(false);

    function handleSidebarToggle() {
        setUserCollapseSidebar((prevValue) => {
            console.log('Sidebar was ' + prevValue);
            console.log('Main value ' + collapseSidebar);

            if (props.device.isDesktop) {
                setCollapseSidebar(!prevValue);
            }
            return !prevValue;
        });
    }

    useEffect(() => {
        if (!isAuthorized) {
            verifyCheck();
        }

        const interval = setInterval(() => {
            if (AUTH_TOKEN !== null && document.visibilityState !== 'hidden') {
                verifyCheck();
            } else {
                // console.log('Tab is hidden!');
            }
        }, authTimeout);

        return () => clearInterval(interval);
    }, []);

    useEffect(() => {
        if (props.device.isMobile) {
            setCollapseSidebar(false);
        } else if (props.device.isTablet) {
            setCollapseSidebar(true);
        } else if (props.device.isDesktop) {
            setCollapseSidebar(userCollapseSidebar);
        }
    }, [props.device]);

    return (
        <div className={`${props.device.isMobile ? 'main-content-mobile' : ''}`}>
            {/* IF TOKEN IS NOT SET AT ALL */}
            {AUTH_TOKEN === null && <Navigate to="/" />}
            {/* IF USER IS NOT AUTHORIZED YET BUT A TOKEN IS FOUND */}
            {!isAuthorized && (
                <div className="center-screen">
                    <Spinner animation="border" />
                </div>
            )}
            {/* IF USER IS AUTHORIZED */}
            {isAuthorized && (
                <>
                    {props.device.isMobile === false && (
                        <TopNavBar
                            collapseControl={handleSidebarToggle}
                            device={props.device}
                            name={userData.name}
                            signOut={signOut}
                        />
                    )}
                    {props.device.isMobile && (
                        <BotNavbar
                            device={props.device}
                            selected={props.selected}
                            name={userData.name}
                            signOut={signOut}
                        />
                    )}
                    <Container fluid className={`${!props.device.isMobile ? 'py-5' : ''}`}>
                        <Row className={`justify-content-center`}>
                            {!props.device.isMobile && (
                                <Col md={`auto`} sm={`auto`} className={`p-0`}>
                                    <Sidebar
                                        isCollapsed={collapseSidebar}
                                        selected={props.selected}
                                        pages={pages}
                                        device={props.device}
                                    />
                                </Col>
                            )}
                            <Col md={`${collapseSidebar ? '10' : '9'}`} sm={`10`}>
                                <Outlet />
                            </Col>
                        </Row>
                    </Container>
                </>
            )}
        </div>
    );
}

export default Dashboard;
