import { Link } from 'react-router-dom';

function ExtractorBreadcrumb(props: any) {
    return (
        <div className="my-2 ms-2 fs-7">
            <Link to="/dashboard/extractors" className="text-primary text-decoration-none">
                Extractors
            </Link>
            <span className="text-muted"> \ {props.name}</span>
        </div>
    );
}

export default ExtractorBreadcrumb;
