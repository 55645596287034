import axios from 'axios';

const BASE_URL = process.env.REACT_APP_API_URL;

export interface Job {
    workflowKey: string;
    name: string;
    schedule: string;
    state: boolean;
    description: string;
    nextRun: number;
    lastRun: number;
    path: string;
    revisionNumber: string;
}

export var emptyJob: Job = {
    workflowKey: '',
    name: '',
    schedule: '',
    state: false,
    description: '',
    nextRun: 0,
    lastRun: 0,
    path: '',
    revisionNumber: '',
};

export interface CreateScheduleDto {
    name: string;
    cronExpression: string;
    workflowKey: string;
    description: string;
    revisionNumber: string;
}

export var emptyCreateScheduleDto: CreateScheduleDto = {
    name: '',
    cronExpression: '',
    workflowKey: '',
    description: '',
    revisionNumber: '',
};

export async function addJob(job: CreateScheduleDto, setError: any, setCancelHandler?: any): Promise<boolean> {
    // KEEPING THIS AS IT IS RIGHT NOW BECAUSE THE CASE WHERE TOKEN IS NOT FOUND IS HANDLED IN OTHER COMPONENTS
    const AUTH_TOKEN = localStorage.getItem('token');

    let controller = new AbortController();

    if (setCancelHandler) {
        setCancelHandler((oldController: AbortController) => {
            if (oldController !== null) {
                oldController.abort();
            }

            return controller;
        });
    } else {
        setError('Incomplete request!');
        throw new Error('Pass cancel handler to this function call to make sure double API calls are avoided.');
    }

    const config = {
        signal: controller.signal,
        headers: { Authorization: `Bearer ${AUTH_TOKEN}` },
    };

    let result = false;

    await axios
        .post(BASE_URL + `/api/v1/schedule`, job, config)
        .then((response) => {
            console.log('success');
            console.log(response);
            result = true;
        })
        .catch((error) => {
            console.log('error');
            console.log(error);
            setError(error.response.data.message);
            result = false;
        });

    return result;
}

export async function getJobs(
    isActive?: number,
    nextRun?: number,
    lastRun?: number,
    sortField?: string,
    sortOrder?: string,
    setCancelHandler?: any,
) {
    let apiRes: any[] = [];
    // KEEPING THIS AS IT IS RIGHT NOW BECAUSE THE CASE WHERE TOKEN IS NOT FOUND IS HANDLED IN OTHER COMPONENTS
    const AUTH_TOKEN = localStorage.getItem('token');

    let query = '';

    if (sortField !== undefined) {
        query += `sortField=${sortField}&`;
    }
    if (sortOrder !== undefined) {
        query += `sortOrder=${sortOrder}&`;
    }
    if (isActive !== undefined) {
        query += `isActive=${isActive === 1 ? true : false}&`;
    }
    if (nextRun !== undefined) {
        query += `nextRun=${nextRun}&`;
    }
    if (lastRun !== undefined) {
        query += `lastRun=${lastRun}`;
    }

    let controller = new AbortController();

    if (setCancelHandler) {
        setCancelHandler((oldController: AbortController) => {
            if (oldController !== null) {
                oldController.abort();
            }

            return controller;
        });
    } else {
        throw new Error('Pass cancel handler to this function call to make sure double API calls are avoided.');
    }

    const config = {
        signal: controller.signal,
        headers: { Authorization: `Bearer ${AUTH_TOKEN}` },
    };

    await axios
        .get(BASE_URL + `/api/v1/schedule?${query}`, config)
        .then((response) => {
            apiRes = response.data;
        })
        .catch((error) => {
            console.log(error);

            apiRes = [];
        });

    return parseSchedules(apiRes);
}

function parseSchedules(apiRes: any) {
    var jobs: Job[] = [];

    apiRes.forEach((job: any) => {
        let tempJob: Job = {
            workflowKey: job.workflowKey,
            name: job.name,
            schedule: job.cronExpression,
            state: job.isActive,
            description: job.description,
            nextRun: job.nextRun,
            lastRun: job.lastRun,
            path: job.path,
            revisionNumber: job.revisionNumber , // set the revision number to 36 if it is not present
        };

        jobs.push(tempJob);
    });

    return jobs;
}

export interface taskHistory {
    description: string;
    status: string;
    timestamp: number;
}

export interface taskExecution {
    name: string;
    attempts: number;
    finishedAt: number;
    status: string;
    startedAt: number;
    history: taskHistory[];
    stats: [];
    type: string;
    liveStats: {};
}

export interface workflowHistory {
    startedBy: string;
    startedAt: number;
    workflowKey: string;
    status: string;
    workflowName: string;
    finishedAt: number;
    jobId: string;
    logsLink?: string;
    taskExecutions: taskExecution[];
}

export async function getWorkflowHistory(workflowKey: string, setCancelHandler?: any) {
    let apiRes: any[] | null = [];
    // KEEPING THIS AS IT IS RIGHT NOW BECAUSE THE CASE WHERE TOKEN IS NOT FOUND IS HANDLED IN OTHER COMPONENTS
    const AUTH_TOKEN = localStorage.getItem('token');

    let controller = new AbortController();

    if (setCancelHandler) {
        setCancelHandler((oldController: AbortController) => {
            if (oldController !== null) {
                oldController.abort();
            }

            return controller;
        });
    } else {
        throw new Error('Pass cancel handler to this function call to make sure double API calls are avoided.');
    }

    const config = {
        signal: controller.signal,
        headers: { Authorization: `Bearer ${AUTH_TOKEN}` },
    };

    await axios
        .get(BASE_URL + `/api/workflow-execution/history/${encodeURIComponent(workflowKey)}`, config)
        .then((response) => {
            apiRes = response.data;
        })
        .catch((error) => {
            console.log(error);

            apiRes = null;
        });

    if (apiRes === null) {
        return null;
    }

    let parsedResponse = parseHistory(apiRes);
    let sortedResponse = sortTaskExecution(parsedResponse);

    return sortedResponse;
}

function sortTaskExecution(parsedResponse: any) {
    parsedResponse.forEach((taskItem: any) => {
        const finalTaskExecutions: taskExecution[] = [];
        const startedExecutions: taskExecution[] = []; 
        const pendingExecutions: taskExecution[] = [];
    
        taskItem.taskExecutions.forEach((taskExecution: taskExecution) => {
            if (taskExecution.history.some((historyItem) => historyItem.status === 'STARTED')) {
                startedExecutions.push(taskExecution);
            }
            else
            {
                pendingExecutions.push(taskExecution);
            }
        });
    
        // taskItem.taskExecutions.forEach((taskExecution: taskExecution) => {
        //     if (taskExecution.history.some((historyItem) => historyItem.status === 'PENDING') && !startedExecutions.some((execution) => execution.name === taskExecution.name)) {
        //         pendingExecutions.push(taskExecution);
        //     }
    
        // });
    
        startedExecutions.sort((a: taskExecution, b: taskExecution) => {
            let aStatus = a.history.find((history: taskHistory) => history.status === 'STARTED');
            let bStatus = b.history.find((history: taskHistory) => history.status === 'STARTED');
            
            if (aStatus === undefined || bStatus === undefined) {
                return 0;
            }

            return aStatus.timestamp - bStatus.timestamp;
        });
    
        pendingExecutions.sort((a: taskExecution, b: taskExecution) => {
            let aStatus = a.history.find((history: taskHistory) => history.status === 'PENDING');
            let bStatus = b.history.find((history: taskHistory) => history.status === 'PENDING');

            if (aStatus === undefined || bStatus === undefined) {
                return 0;
            }

            return aStatus.timestamp - bStatus.timestamp;
        });

        finalTaskExecutions.push(...startedExecutions);
        finalTaskExecutions.push(...pendingExecutions);
        taskItem.taskExecutions = finalTaskExecutions;
    });

    return parsedResponse;
}

function parseHistory(apiRes: any) {
    var history: workflowHistory[] = [];

    apiRes.forEach((historyItem: any) => {
        if (historyItem.startedAt === undefined) {
            console.log(historyItem);
        }

        // Continuing if we don't have task executions
        if (!Object.keys(historyItem).includes('taskExecutions') || historyItem.taskExecutions === undefined) {
            return;
        }

        let tempHistory: workflowHistory = {
            startedBy: historyItem.startedBy || '',
            startedAt: historyItem.startedAt || '',
            workflowKey: historyItem.workflowKey || '',
            status: historyItem.status || '',
            workflowName: historyItem.workflowName || '',
            finishedAt: historyItem.finishedAt || '',
            jobId: historyItem.jobId || '',
            taskExecutions: [],
            logsLink: historyItem.logsLink || null,
        };

        Object.keys(historyItem.taskExecutions).forEach((name: any) => {
            let curExecution = historyItem.taskExecutions[name];

            if (curExecution.startedAt === undefined) {
                console.log(curExecution);
            }

            let tempTaskExecution: taskExecution = {
                name: curExecution.name || '',
                attempts: curExecution.attempts || 0,
                finishedAt: curExecution.finishedAt || '',
                status: curExecution.status || '',
                startedAt: curExecution.startedAt || 0,
                history: [],
                stats: curExecution.stats || [],
                liveStats: curExecution.liveStats || {},
                type: curExecution.type || '',
            };

            curExecution.history.forEach((taskHistory: any) => {
                let tempTaskHistory: taskHistory = {
                    description: taskHistory.description || '',
                    status: taskHistory.status || '',
                    timestamp: taskHistory.timestamp || '',
                };

                tempTaskExecution.history.push(tempTaskHistory);
            });

            tempHistory.taskExecutions.push(tempTaskExecution);
        });

        history.push(tempHistory);
    });

    return history;
}

export async function retryJob(
    workflowKey: string,
    jobId: string | undefined,
    scheduleName: string | undefined,
    revisionNumber: number | undefined,
    setSuccess: Function,
    setError: Function,
    setCancelHandler?: any,
) {
    // KEEPING THIS AS IT IS RIGHT NOW BECAUSE THE CASE WHERE TOKEN IS NOT FOUND IS HANDLED IN OTHER COMPONENTS
    const AUTH_TOKEN = localStorage.getItem('token');

    let controller = new AbortController();

    if (setCancelHandler) {
        setCancelHandler((oldController: AbortController) => {
            if (oldController !== null) {
                oldController.abort();
            }

            return controller;
        });
    } else {
        throw new Error('Pass cancel handler to this function call to make sure double API calls are avoided.');
    }

    const data = {
        jobId: jobId,
        scheduleName: scheduleName,
        revisionNumber: revisionNumber,
    };

    // Remove scheduleName from data if it is undefined
    if (scheduleName === undefined) {
        delete data.scheduleName;
    }

    if (jobId === undefined || jobId === '') {
        delete data.jobId;
    }

    if (revisionNumber === undefined || revisionNumber === 0) {
        delete data.revisionNumber;
    }

    const config = {
        signal: controller.signal,
        headers: { Authorization: `Bearer ${AUTH_TOKEN}` },
    };

    await axios
        .post(BASE_URL + `/api/workflow/${encodeURIComponent(workflowKey)}/task/retry`, data, config)
        .then((response) => {
            console.log(response);
            if (response.status === 200) {
                setSuccess('Successfully deployed!');
            } else {
                setError(response.data.message);
            }
        })
        .catch((error) => {
            console.log(error);
            setError(error.response.data.message);
        });
}

export async function stopyJob(
    workflowKey: string,
    jobId: string,
    startedAt: number,
    setSuccess: Function,
    setError: Function,
    setCancelHandler?: any,
) {
    // KEEPING THIS AS IT IS RIGHT NOW BECAUSE THE CASE WHERE TOKEN IS NOT FOUND IS HANDLED IN OTHER COMPONENTS
    const AUTH_TOKEN = localStorage.getItem('token');

    let controller = new AbortController();

    if (setCancelHandler) {
        setCancelHandler((oldController: AbortController) => {
            if (oldController !== null) {
                oldController.abort();
            }

            return controller;
        });
    } else {
        throw new Error('Pass cancel handler to this function call to make sure double API calls are avoided.');
    }

    const data = {
        jobId: jobId,
        startedAt: startedAt,
    };

    const config = {
        signal: controller.signal,
        headers: { Authorization: `Bearer ${AUTH_TOKEN}` },
    };

    await axios
        .post(BASE_URL + `/api/workflow/${encodeURIComponent(workflowKey)}/task/stop`, data, config)
        .then((response) => {
            if (response.status === 200) {
                setSuccess('Successfully stoped!');
            } else {
                setError(response.data.message);
            }
        })
        .catch((error) => {
            setError(error.response.data.message);
        });
}

export async function updateJobBackend(job: Job, setError: Function, setCancelHandler?: any) {
    // KEEPING THIS AS IT IS RIGHT NOW BECAUSE THE CASE WHERE TOKEN IS NOT FOUND IS HANDLED IN OTHER COMPONENTS
    const AUTH_TOKEN = localStorage.getItem('token');

    let controller = new AbortController();

    if (setCancelHandler) {
        setCancelHandler((oldController: AbortController) => {
            if (oldController !== null) {
                oldController.abort();
            }

            return controller;
        });
    } else {
        throw new Error('Pass cancel handler to this function call to make sure double API calls are avoided.');
    }

    const config = {
        signal: controller.signal,
        headers: { Authorization: `Bearer ${AUTH_TOKEN}` },
    };

    const payload = {
        name: job.name,
        cronExpression: job.schedule,
        description: job.description,
        state: job.state ? 'ENABLED' : 'DISABLED',
        revisionNumber: job.revisionNumber,
    };

    let result: Boolean = false;

    await axios
        .put(BASE_URL + `/api/v1/schedule`, payload, config)
        .then((response) => {
            if (response.status === 200) {
                result = true;
            } else {
                setError(response.data.message);
            }
        })
        .catch((error) => {
            console.log(error);
            setError(error.response.data.message);
        });

    return result;
}

export interface Workflow {
    basePath: string;
    path: string;
    updatedAt: string;
    createdAt: string;
    jobConfigFilename: string;
    key: string;
    name: string;
}

export interface WorkflowGroup {
    [key: string]: Workflow[];
    // add other expected keys and values here
}

export async function getWorkflows(setCancelHandler?: any): Promise<WorkflowGroup> {
    let apiRes: WorkflowGroup = {};
    // KEEPING THIS AS IT IS RIGHT NOW BECAUSE THE CASE WHERE TOKEN IS NOT FOUND IS HANDLED IN OTHER COMPONENTS
    const AUTH_TOKEN = localStorage.getItem('token');

    let controller = new AbortController();

    if (setCancelHandler) {
        setCancelHandler((oldController: AbortController) => {
            if (oldController !== null) {
                oldController.abort();
            }

            return controller;
        });
    } else {
        throw new Error('Pass cancel handler to this function call to make sure double API calls are avoided.');
    }

    const config = {
        signal: controller.signal,
        headers: { Authorization: `Bearer ${AUTH_TOKEN}` },
    };

    let temp: [];
    let groupKeys: string[] = [];

    await axios
        .get(BASE_URL + `/api/workflow`, config)
        .then((response) => {
            temp = response.data;

            // Generating Keys
            temp.map((item: Workflow) => {
                if (!groupKeys.includes(item.basePath)) {
                    groupKeys.push(item.basePath);
                }
                return item;
            });

            // Preparing the Final Result
            temp.map((item: Workflow) => {
                if (apiRes[item.basePath] === undefined) {
                    apiRes[item.basePath] = [];
                }
                apiRes[item.basePath].push(item);

                return item;
            });
            console.log(apiRes);
        })
        .catch((error) => {
            console.log(error);
            throw error;
        });

    // Get all the basePath keys
    let sortedKeys: string[] = Object.keys(apiRes);

    // Sorting the keys
    sortedKeys.sort((a: string, b: string) => {
        if (a.toLowerCase() < b.toLowerCase()) {
            return -1;
        } else if (a.toLowerCase() > b.toLowerCase()) {
            return 1;
        } else {
            return 0;
        }
    });

    // Creating a new object with sorted keys
    let sortedApiRes: WorkflowGroup = {};

    sortedKeys.forEach((key: string) => {
        sortedApiRes[key] = apiRes[key];
    });

    // Sorting the values in each key based on key
    Object.keys(sortedApiRes).forEach((key: string) => {
        sortedApiRes[key].sort((a: Workflow, b: Workflow) => {
            if (a.key.toLowerCase() < b.key.toLowerCase()) {
                return -1;
            } else if (a.key.toLowerCase() > b.key.toLowerCase()) {
                return 1;
            } else {
                return 0;
            }
        });
    });

    return sortedApiRes;
}

export async function runWorkflow(
    workflowKey: string,
    setSuccess: Function,
    setError: Function,
    setCancelHandler?: any,
) {
    // KEEPING THIS AS IT IS RIGHT NOW BECAUSE THE CASE WHERE TOKEN IS NOT FOUND IS HANDLED IN OTHER COMPONENTS
    const AUTH_TOKEN = localStorage.getItem('token');

    let controller = new AbortController();

    if (setCancelHandler) {
        setCancelHandler((oldController: AbortController) => {
            if (oldController !== null) {
                oldController.abort();
            }

            return controller;
        });
    } else {
        throw new Error('Pass cancel handler to this function call to make sure double API calls are avoided.');
    }

    const config = {
        signal: controller.signal,
        headers: { Authorization: `Bearer ${AUTH_TOKEN}` },
    };

    await axios
        .get(BASE_URL + `/api/workflow/${encodeURIComponent(workflowKey)}/task/run`, config)
        .then((response) => {
            console.log(response);
            if (response.status === 200) {
                setSuccess('Successfully deployed!');
            } else {
                setError(response.data.message);
            }
        })
        .catch((error) => {
            console.log(error);
            setError(error.response.data.message);
        });
}

export async function syncWorkflows(
    setSuccess: Function,
    setSuccessDescription: Function,
    setError: Function,
    setCancelHandler?: any,
) {
    // KEEPING THIS AS IT IS RIGHT NOW BECAUSE THE CASE WHERE TOKEN IS NOT FOUND IS HANDLED IN OTHER COMPONENTS
    const AUTH_TOKEN = localStorage.getItem('token');

    let controller = new AbortController();

    if (setCancelHandler) {
        setCancelHandler((oldController: AbortController) => {
            if (oldController !== null) {
                oldController.abort();
            }

            return controller;
        });
    } else {
        throw new Error('Pass cancel handler to this function call to make sure double API calls are avoided.');
    }

    const config = {
        signal: controller.signal,
        headers: { Authorization: `Bearer ${AUTH_TOKEN}` },
    };

    await axios
        .get(BASE_URL + `/api/workflow/sync`, config)
        .then((response) => {
            console.log(response);
            if (response.status === 202) {
                setSuccess('Synchronization started...');
                setSuccessDescription(
                    'This process may take longer time depending on the number of workflows in your repository. Please wait until the process is completed.',
                );
            } else {
                setSuccess(null);
                setSuccessDescription(null);
                setError(response.data.message);
            }
        })
        .catch((error) => {
            console.log(error);
            setSuccess(null);
            setSuccessDescription(null);
            setError('Something went wrong!');
        });
}

export async function syncSpecificWorkflows(
    basePath: string,
    setSuccess: Function,
    setSuccessDescription: Function,
    setError: Function,
    setCancelHandler?: any,
) {
    // KEEPING THIS AS IT IS RIGHT NOW BECAUSE THE CASE WHERE TOKEN IS NOT FOUND IS HANDLED IN OTHER COMPONENTS
    const AUTH_TOKEN = localStorage.getItem('token');

    let controller = new AbortController();

    if (setCancelHandler) {
        setCancelHandler((oldController: AbortController) => {
            if (oldController !== null) {
                oldController.abort();
            }

            return controller;
        });
    } else {
        throw new Error('Pass cancel handler to this function call to make sure double API calls are avoided.');
    }

    const config = {
        signal: controller.signal,
        headers: { Authorization: `Bearer ${AUTH_TOKEN}` },
    };

    await axios
        .get(BASE_URL + `/api/workflow/sync/${basePath}`, config)
        .then((response) => {
            console.log(response);
            if (response.status === 202) {
                setSuccess('Synchronization successful!');
                setSuccessDescription(response.data.message);
            } else {
                setSuccess(null);
                setSuccessDescription(null);
                setError(response.data.message);
            }
        })
        .catch((error) => {
            console.log(error);
            setSuccess(null);
            setSuccessDescription(null);
            setError('Something went wrong!');
        });
}

export interface SummaryData {
    running: workflowHistory[];
    successful: workflowHistory[];
    failed: workflowHistory[];
    upcoming: Job[];
}

export async function getSummary(setCancelHandler?: any): Promise<SummaryData | null> {
    // KEEPING THIS AS IT IS RIGHT NOW BECAUSE THE CASE WHERE TOKEN IS NOT FOUND IS HANDLED IN OTHER COMPONENTS
    const AUTH_TOKEN = localStorage.getItem('token');

    let controller = new AbortController();

    if (setCancelHandler) {
        setCancelHandler((oldController: AbortController) => {
            if (oldController !== null) {
                oldController.abort();
            }

            return controller;
        });
    } else {
        throw new Error('Pass cancel handler to this function call to make sure double API calls are avoided.');
    }

    const config = {
        signal: controller.signal,
        headers: { Authorization: `Bearer ${AUTH_TOKEN}` },
    };

    // Create a new Date object for the current date
    const currentDate = new Date();

    // Set the time of the date object to 00:00am
    currentDate.setHours(0, 0, 0, 0);

    // Get the timestamp in seconds
    const timestamp = currentDate.getTime() / 1000;

    let result: SummaryData | null = null;

    await axios
        .get(BASE_URL + `/api/workflow-execution/summary/${timestamp}`, config)
        .then((response) => {
            result = {
                running: parseHistory(response.data.running),
                successful: parseHistory(response.data.successful),
                failed: parseHistory(response.data.failed),
                upcoming: parseSchedules(response.data.upcoming),
            };
            result.running = sortTaskExecution(result.running);
            result.successful = sortTaskExecution(result.successful);
            result.failed = sortTaskExecution(result.failed);
        })
        .catch((error) => {
            console.log(error);
            // setError(error.response.data.message)
            result = null;
        });

    return result;
}

export function generatePackageGithubUrl(workflowKey: string) {
    // Splitting workflow key into directory path and job config name
    const path = workflowKey.split('::')[0];
    const jobConfigName = workflowKey.split('::')[1];

    // Generating github url
    const githubUrl = `https://github.com/crawlnow/crawl-packages/blob/main/packages/${path}/${jobConfigName}`;

    return githubUrl;
}

export function copyToClipboard(text: string) {
    navigator.clipboard.writeText(text);
}

export interface ExtractorVariable {
    name: string;
    description: string;
    required: boolean;
    example: string;
}

export interface Extractor {
    key: string;
    name: string;
    path: string;
    updatedAt: string;
    variables: ExtractorVariable[];
    description: string;
    sampleOutput: string;
}

export interface variableDto {
    [key: string]: string;
    // add other expected keys and values here
}

export interface runExtractorDto {
    key: string;
    variables: variableDto;
}

export const emptyExtractorDto: runExtractorDto = {
    key: '',
    variables: {},
};

export async function getExtractorData(setCancelHandler?: any): Promise<Extractor[]> {
    let apiRes: Extractor[] = [];
    // KEEPING THIS AS IT IS RIGHT NOW BECAUSE THE CASE WHERE TOKEN IS NOT FOUND IS HANDLED IN OTHER COMPONENTS
    const AUTH_TOKEN = localStorage.getItem('token');

    let controller = new AbortController();

    if (setCancelHandler) {
        setCancelHandler((oldController: AbortController) => {
            if (oldController !== null) {
                oldController.abort();
            }

            return controller;
        });
    } else {
        throw new Error('Pass cancel handler to this function call to make sure double API calls are avoided.');
    }

    const config = {
        signal: controller.signal,
        headers: { Authorization: `Bearer ${AUTH_TOKEN}` },
    };

    await axios
        .get(BASE_URL + `/api/extractor`, config)
        .then((response) => {
            apiRes = response.data;

            console.log(apiRes);
        })
        .catch((error) => {
            console.log(error);
            throw error;
        });

    return apiRes;
}

// TODO: STANDARDIZE THE VARIABLE NAMES LATER
const INPUT_VARIABLE_FORMAT = 'INPUT_FILENAME';

// Function to get required variables only from array of variables
// We don't need input variables here because they are always required
export function getRequiredExtractorVariables(variables: ExtractorVariable[]) {
    let requiredVariables: ExtractorVariable[] = [];

    variables.forEach((variable: ExtractorVariable) => {
        if (variable.required && !variable.name.includes(INPUT_VARIABLE_FORMAT)) {
            requiredVariables.push(variable);
        }
    });

    return requiredVariables;
}

// Function to get input variables only from array of variables
// Input variables are always required, when writing extractors, this should be kept in mind
export function getInputExtractorVariables(variables: ExtractorVariable[]) {
    let inputVariables: ExtractorVariable[] = [];

    variables.forEach((variable: ExtractorVariable) => {
        if (variable.name.includes(INPUT_VARIABLE_FORMAT)) {
            inputVariables.push(variable);
        }
    });

    return inputVariables;
}

// Function to get optional variables only from array of variables
// We don't need input variables here because they are always required
export function getOptionalExtractorVariables(variables: ExtractorVariable[]) {
    let optionalVariables: ExtractorVariable[] = [];

    variables.forEach((variable: ExtractorVariable) => {
        if (!variable.required && !variable.name.includes(INPUT_VARIABLE_FORMAT)) {
            optionalVariables.push(variable);
        }
    });

    return optionalVariables;
}

export async function handleLaunchExtractor(
    runExtractorDto: runExtractorDto,
    setSuccess: Function,
    setError: any,
    setCancelHandler?: any,
) {
    // KEEPING THIS AS IT IS RIGHT NOW BECAUSE THE CASE WHERE TOKEN IS NOT FOUND IS HANDLED IN OTHER COMPONENTS
    const AUTH_TOKEN = localStorage.getItem('token');

    let controller = new AbortController();

    if (setCancelHandler) {
        setCancelHandler((oldController: AbortController) => {
            if (oldController !== null) {
                oldController.abort();
            }

            return controller;
        });
    } else {
        setError('Incomplete request!');
        throw new Error('Pass cancel handler to this function call to make sure double API calls are avoided.');
    }

    const data = {
        variables: runExtractorDto.variables,
    };

    const extractorKey = runExtractorDto.key;

    const config = {
        signal: controller.signal,
        headers: { Authorization: `Bearer ${AUTH_TOKEN}` },
    };

    await axios
        .post(BASE_URL + `/api/extractor/${encodeURIComponent(extractorKey)}/task/run`, data, config)
        .then((response) => {
            console.log(response);
            if (response.status === 200) {
                setSuccess('Successfully deployed!');
            } else {
                setError(response.data.message);
            }
        })
        .catch((error) => {
            console.log(error);
            setError(error.response.data.message);
        });
}

export async function syncExtractor(
    setSuccess: Function,
    setSuccessDescription: Function,
    setError: Function,
    setCancelHandler?: any,
) {
    // KEEPING THIS AS IT IS RIGHT NOW BECAUSE THE CASE WHERE TOKEN IS NOT FOUND IS HANDLED IN OTHER COMPONENTS
    const AUTH_TOKEN = localStorage.getItem('token');

    let controller = new AbortController();

    if (setCancelHandler) {
        setCancelHandler((oldController: AbortController) => {
            if (oldController !== null) {
                oldController.abort();
            }

            return controller;
        });
    } else {
        throw new Error('Pass cancel handler to this function call to make sure double API calls are avoided.');
    }

    const config = {
        signal: controller.signal,
        headers: { Authorization: `Bearer ${AUTH_TOKEN}` },
    };

    await axios
        .get(BASE_URL + `/api/extractor/sync`, config)
        .then((response) => {
            console.log(response);
            if (response.status === 202) {
                setSuccess('Synchronization successful!');
                setSuccessDescription(response.data.message);
                console.log('extractor synced successfully');
            } else {
                setSuccess(null);
                setSuccessDescription(null);
                setError(response.data.message);
            }
        })
        .catch((error) => {
            console.log(error);
            setSuccess(null);
            setSuccessDescription(null);
            setError('Something went wrong!');
        });
}

export interface FileDetails {
    Key: string;
    filesize: string;
    datemodified: string;
}

export async function getExtractorInputFiles(
    extractorKey: string,
    setError: Function,
    setCancelHandler?: any,
): Promise<FileDetails[]> {
    let apiRes: FileDetails[] = [];

    const AUTH_TOKEN = localStorage.getItem('token');

    let controller = new AbortController();

    if (setCancelHandler) {
        setCancelHandler((oldController: AbortController) => {
            if (oldController !== null) {
                oldController.abort();
            }

            return controller;
        });
    } else {
        throw new Error('Pass cancel handler to this function call to make sure double API calls are avoided.');
    }

    const config = {
        signal: controller.signal,
        headers: { Authorization: `Bearer ${AUTH_TOKEN}` },
    };

    await axios
        .get(BASE_URL + `/api/extractor/${encodeURIComponent(extractorKey)}/input`, config)
        .then((response) => {
            apiRes = response.data;

            console.log(apiRes);
        })
        .catch((error) => {
            setError(error.response.data.message);
            console.log(error);
            // throw error;
        });

    return apiRes;
}

export async function uploadExtractorInputFile(
    extractorKey: string,
    inputFile: File,
    setError: Function,
    setCancelHandler?: any,
): Promise<string | null> {
    const AUTH_TOKEN = localStorage.getItem('token');

    let controller = new AbortController();

    if (setCancelHandler) {
        setCancelHandler((oldController: AbortController) => {
            if (oldController !== null) {
                oldController.abort();
            }

            return controller;
        });
    } else {
        throw new Error('Pass cancel handler to this function call to make sure double API calls are avoided.');
    }

    let data = new FormData();
    data.append('file', inputFile);

    const config = {
        signal: controller.signal,
        headers: { Authorization: `Bearer ${AUTH_TOKEN}`, 'Content-Type': 'multipart/form-data' },
        // data : data
    };

    console.log('config', config);

    let result = null;

    await axios
        .post(BASE_URL + `/api/extractor/${encodeURIComponent(extractorKey)}/input`, data, config)
        .then((response) => {
            if (response.status === 200) {
                console.log(response.data.message);
                result = inputFile.name;
            } else {
                setError(response.data.message);
            }
        })
        .catch((error) => {
            console.log(error);
            setError(error.response.data.message);
        });

    return result;
}

export async function downloadExtractorSampleOutput(extractorKey: string, setSuccess: Function, setError: Function, setCancelHandler?: any) {
    const AUTH_TOKEN = localStorage.getItem('token');

    let controller = new AbortController();

    let result = null;

    if (setCancelHandler) {
        setCancelHandler((oldController: AbortController) => {
            if (oldController !== null) {
                oldController.abort();
            }

            return controller;
        });
    } else {
        throw new Error('Pass cancel handler to this function call to make sure double API calls are avoided.');
    }
    const config = {
        signal: controller.signal,
        headers: { Authorization: `Bearer ${AUTH_TOKEN}` },
    };

    await axios
        .get(BASE_URL + `/api/extractor/${encodeURIComponent(extractorKey)}/sample`, config)
        .then((response) => {
            console.log(response);
            if (response.status === 200) {
                result = response.data;
                setSuccess('Downloading...');
            } else {
                setError(response.data.message);
            }
        })
        .catch((error) => {
            console.log(error);
            setError(error.response.data.message);
        });

    if (result !== null) {
        const downloadURL = result['url'];

        // Downloading the file in a new tab
        window.open(downloadURL, '_blank');
    }
}

export async function downloadExtractorSampleInput(extractorKey: string, setSuccess: Function, setError: Function, setCancelHandler?: any) {
    const AUTH_TOKEN = localStorage.getItem('token');

    let controller = new AbortController();

    let result = null;

    if (setCancelHandler) {
        setCancelHandler((oldController: AbortController) => {
            if (oldController !== null) {
                oldController.abort();
            }

            return controller;
        });
    } else {
        throw new Error('Pass cancel handler to this function call to make sure double API calls are avoided.');
    }
    const config = {
        signal: controller.signal,
        headers: { Authorization: `Bearer ${AUTH_TOKEN}` },
    };

    await axios
        .get(BASE_URL + `/api/extractor/${encodeURIComponent(extractorKey)}/sample?fileName=input.csv`, config)
        .then((response) => {
            if (response.status === 200) {
                result = response.data;
                setSuccess('Downloading...');
            } else {
                setError(response.data.message);
            }
        })
        .catch((error) => {
            setError(error.response.data.message);
        });

    if (result !== null) {
        const downloadURL = result['url'];

        // Downloading the file in a new tab
        window.open(downloadURL, '_blank');
    }

    // return errorMsg;
}

export async function getWorkflowsKeys(setCancelHandler?: any): Promise<WorkflowGroup> {
    let apiRes: WorkflowGroup = {};
    // KEEPING THIS AS IT IS RIGHT NOW BECAUSE THE CASE WHERE TOKEN IS NOT FOUND IS HANDLED IN OTHER COMPONENTS
    const AUTH_TOKEN = localStorage.getItem('token');

    let controller = new AbortController();

    if (setCancelHandler) {
        setCancelHandler((oldController: AbortController) => {
            if (oldController !== null) {
                oldController.abort();
            }

            return controller;
        });
    } else {
        throw new Error('Pass cancel handler to this function call to make sure double API calls are avoided.');
    }

    const config = {
        signal: controller.signal,
        headers: { Authorization: `Bearer ${AUTH_TOKEN}` },
    };

    let temp: [];
    let groupKeys: string[] = [];

    await axios
        .get(BASE_URL + `/api/workflow`, config)
        .then((response) => {
            temp = response.data;

            // Generating Keys
            temp.map((item: Workflow) => {
                if (!groupKeys.includes(item.key)) {
                    groupKeys.push(item.key);
                }
                return item;
            });

            // Preparing the Final Result
            temp.map((item: Workflow) => {
                if (apiRes[item.key] === undefined) {
                    apiRes[item.key] = [];
                }
                apiRes[item.key].push(item);

                return item;
            });
        })
        .catch((error) => {
            console.log(error);
            throw error;
        });

    return apiRes;
}

export async function deleteScheduledJob(
    name: string,
    setSuccess: Function,
    setError: Function,
    setCancelHandler?: any,
) {
    const AUTH_TOKEN = localStorage.getItem('token');
    let controller = new AbortController();

    if (setCancelHandler) {
        setCancelHandler((oldController: AbortController) => {
            if (oldController !== null) {
                oldController.abort();
            }
            return controller;
        });
    } else {
        throw new Error('Pass cancel handler to this function call to make sure double API calls are avoided.');
    }

    const config = {
        signal: controller.signal,
        headers: { Authorization: `Bearer ${AUTH_TOKEN}` },
    };

    await axios
        .delete(BASE_URL + `/api/v1/schedule/schedule-jobs/${name}`, config)
        .then((response) => {
            if (response.status === 200) {
                setSuccess('Successfully deleted!');
            } else {
                setSuccess(null);
                setError(response.data.message);
            }
        })
        .catch((error) => {
            console.log(error);
            setError(error.response.data.message);
        });
}

export async function getTaskDefinitions(setCancelHandler?: any): Promise<any> {
    let apiRes: any = [];
    const AUTH_TOKEN = localStorage.getItem('token');
    let controller = new AbortController();

    if (setCancelHandler) {
        setCancelHandler((oldController: AbortController) => {
            if (oldController !== null) {
                oldController.abort();
            }
            return controller;
        });
    } else {
        throw new Error('Pass cancel handler to this function call to make sure double API calls are avoided.');
    }
    const config = {
        signal: controller.signal,
        headers: { Authorization: `Bearer ${AUTH_TOKEN}` },
    };

    await axios
        .get(BASE_URL + `/api/workflow/taskDefinitions`, config)
        .then((response) => {
            apiRes = response.data;
        })
        .catch((error) => {
            console.log(error);
            throw error;
        });
    // console.log("task definitions", apiRes);
    return apiRes;
}

export async function markTaskAsDone(
    workflowKey: string,
    workflowName: string,
    jobId: string,
    taskName: string,
    setSuccess: Function,
    setError: Function,
    setCancelHandler?: any,
) {
    const AUTH_TOKEN = localStorage.getItem('token');
    let controller = new AbortController();
    let data = {
        workflowKey: workflowKey,
        workflowName: workflowName,
        jobId: jobId,
        taskName: taskName,
    };
    if (setCancelHandler) {
        setCancelHandler((oldController: AbortController) => {
            if (oldController !== null) {
                oldController.abort();
            }
            return controller;
        });
    } else {
        throw new Error('Pass cancel handler to this function call to make sure double API calls are avoided.');
    }
    const config = {
        signal: controller.signal,
        headers: { Authorization: `Bearer ${AUTH_TOKEN}` },
    };
    await axios
        .post(BASE_URL + `/api/workflow/task/done`, data, config)
        .then((response) => {
            if (response.status === 200 || response.status === 204) {
                setSuccess('Successfully marked as done!');
            } else {
                setError(response.data.message);
            }
        })
        .catch((error) => {
            setError(error.response.data.message);
        });
}

export async function downloadECSFile(setCancelHandler?: any) {
    const AUTH_TOKEN = localStorage.getItem('token');
    let controller = new AbortController();

    let data = {
        workflowName: 'dedupe',
        jobId: 'test_1234',
        taskName: 'dedupe-task-5',
        fileName: 'post_dedupe_5.csv',
    };
    if (setCancelHandler) {
        setCancelHandler((oldController: AbortController) => {
            if (oldController !== null) {
                oldController.abort();
            }
            return controller;
        });
    } else {
        throw new Error('Pass cancel handler to this function call to make sure double API calls are avoided.');
    }
    const config = {
        signal: controller.signal,
        headers: { Authorization: `Bearer ${AUTH_TOKEN}` },
    };
    await axios
        .post(BASE_URL + `/api/workflow/task/downloadECSFile`, data, config)
        .then((response) => {
            if (response.status === 200 || response.status === 204) {
                // the response is streamable file so we need to convert it to blob
                const blob = new Blob([response.data], { type: 'application/octet-stream' });
                const url = window.URL.createObjectURL(blob);
                const a = document.createElement('a');
                a.href = url;
                a.download = data.fileName;
                a.click();
                window.URL.revokeObjectURL(url);

            } else {
                console.log(response.data.message);
            }
            console.log('response is ', response);
        })
        .catch((error) => {
            console.log(error.response.data.message);
        });
}

export interface downloadECSFileChunkDto {
    workflowName: string;
    jobId: string;
    taskName: string;
    fileName: string;
    dzChunkByteOffset?: string;
    dzChunkIndex?: string;
}

export var emptyDownloadECSFileChunkDto: downloadECSFileChunkDto = {
    workflowName: '',
    jobId: '',
    taskName: '',
    fileName: '',
    dzChunkByteOffset: '',
    dzChunkIndex: ''
};

function base64ToUint8Array(base64: string): Uint8Array {
    const binaryString = window.atob(base64);
    const len = binaryString.length;
    const bytes = new Uint8Array(len);
    for (let i = 0; i < len; i++) {
        bytes[i] = binaryString.charCodeAt(i);
    }
    return bytes;
}

export async function downloadECSFileChunk(data: downloadECSFileChunkDto, setSuccess: Function, setError: Function, setCancelHandler?: any) {
    const AUTH_TOKEN = localStorage.getItem('token');
    let controller = new AbortController();

    data.fileName = 'feed.csv'; 
    console.log("data is ", data);

    if (setCancelHandler) {
        setCancelHandler((oldController: AbortController) => {
            if (oldController !== null) {
                oldController.abort();
            }
            return controller;
        });
    } else {
        throw new Error('Pass cancel handler to this function call to make sure double API calls are avoided.');
    }
    const config = {
        signal: controller.signal,
        headers: { Authorization: `Bearer ${AUTH_TOKEN}` },
    };

    const chunks: Uint8Array[] = [];
    let chunk_data = null;

    while (chunk_data !== '') {
        await axios
            .post(BASE_URL + `/api/workflow/task/downloadECSFileChunk`, data, config)
            .then((response) => {
                if (response.status === 200 || response.status === 204) {
                    chunk_data = response.data.chunk_data;

                    if (chunk_data.length > 0) {
                        const chunkBuffer = base64ToUint8Array(chunk_data);
                        chunks.push(chunkBuffer);
                    } else {
                        chunk_data = '';  // Stop loop if the chunk is empty
                    }
                    data = {
                        ...data,
                        dzChunkByteOffset: response.data.dzchunkbyteoffset,
                        dzChunkIndex: response.data.dzchunkindex,
                    };
                } else {
                    chunk_data = ''; // Stop loop on error
                    setError(response.data.message);
                    // console.log(response.data.message);  // set the error here
                }
            })
            .catch((error) => {
                chunk_data = '';
                setError(error.response.data.message);
                // console.log(error.response.data.message); // set the error here
            });
    }

    if (chunks.length > 0) {
        const blob = new Blob(chunks, { type: 'application/octet-stream' });
        console.log("blob is ", blob);
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = url;
        a.download = data.fileName;
        a.click();
        window.URL.revokeObjectURL(url);
        setSuccess('Downloaded successfully!');
    } else {
        setError('No data was downloaded.');
        // console.log('No data was downloaded.'); // set the error here
    }
}


export async function retryExtractorJob(
    extractorKey: string,
    jobId: string,
    revisionNumber: string | undefined,
    setSuccess: Function,
    setError: Function,
    setCancelHandler?: any
) {
    const AUTH_TOKEN = localStorage.getItem('token');
    let controller = new AbortController();
    const data = {
        jobId: jobId,
        revisionNumber: revisionNumber,
    };

    if (revisionNumber === undefined || revisionNumber === '') {
        delete data.revisionNumber;
    }

    if (setCancelHandler) {
        setCancelHandler((oldController: AbortController) => {
            if (oldController !== null) {
                oldController.abort();
            }
            return controller;
        });
    } else {
        throw new Error('Pass cancel handler to this function call to make sure double API calls are avoided.');
    }
    const config = {
        signal: controller.signal,
        headers: { Authorization: `Bearer ${AUTH_TOKEN}` },
    };

    await axios
        .post(BASE_URL + `/api/extractor/${encodeURIComponent(extractorKey)}/task/retry`, data, config)
        .then((response) => {
            if (response.status === 200) {
                setSuccess('Successfully Restarted!');
            } else {
                setError(response.data.message);
            }
        })
        .catch((error) => {
            setError(error.response.data.message);
        });
}
