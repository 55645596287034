import './BotNavbar.css';

import {
    PersonIcon,
    SignOutIcon,
    HomeIcon,
    ServerIcon,
    GitBranchIcon,
    DesktopDownloadIcon,
} from '@primer/octicons-react';
import { Link } from 'react-router-dom';

function BotNavbar(props: any) {
    return (
        <nav className="navbar navbar-bot navbar-dark bg-primary-dark fixed-bottom">
            <div className="d-flex gap-3 justify-content-center container-fluid">
                <Link
                    to={`/dashboard/home`}
                    className={`${props.selected === 'home' ? 'text-light bg-primary' : ''} p-2 rounded-3`}
                >
                    <span>
                        <HomeIcon size={24} />
                    </span>
                </Link>
                <Link
                    to={`/dashboard/scheduled`}
                    className={`${
                        props.selected === 'scheduled' ? 'text-light bg-primary' : ''
                    } p-2 rounded-3 nav-item`}
                >
                    <span>
                        <ServerIcon size={24} />
                    </span>
                </Link>
                <Link
                    to={`/dashboard/workflows`}
                    className={`${
                        props.selected === 'workflows' ? 'text-light bg-primary' : ''
                    } p-2 rounded-3 nav-item`}
                >
                    <span>
                        <GitBranchIcon size={24} />
                    </span>
                </Link>
                <Link
                    to={`/dashboard/extractors`}
                    className={`${
                        props.selected === 'extractors' ? 'text-light bg-primary' : ''
                    } p-2 rounded-3 nav-item`}
                >
                    <span>
                        <DesktopDownloadIcon size={24} />
                    </span>
                </Link>
                <Link to={`#`} className={`p-2 rounded-3`}>
                    <span>
                        <PersonIcon size={24} />
                    </span>
                </Link>
                <Link to={`#`} onClick={props.signOut} className={`p-2 rounded-3`}>
                    <span>
                        <SignOutIcon size={24} />
                    </span>
                </Link>
            </div>
        </nav>
    );
}

export default BotNavbar;
