import { useState, useEffect } from 'react';
import { Ratio, Toast, ToastContainer, OverlayTrigger, Tooltip } from 'react-bootstrap';
import FloatingLabel from 'react-bootstrap/FloatingLabel';
import { InfoIcon } from '@primer/octicons-react';
import './RunExtractor.css';

import { Button, Collapse, Form, Modal, Spinner } from 'react-bootstrap';
import {
    ExtractorVariable,
    runExtractorDto,
    emptyExtractorDto,
    getRequiredExtractorVariables,
    getInputExtractorVariables,
    getOptionalExtractorVariables,
    handleLaunchExtractor,
} from '../../services/jobs.service';

import UploadFile from './UploadFile';

function RunExtractor(props: any) {
    const [data, setData] = useState<runExtractorDto>({ ...emptyExtractorDto, variables: {} });
    const [selectedKey, setSelectedKey] = useState<string | undefined>();
    const [awaiting, setAwaiting] = useState(false);
    const [dataValid, setDataValid] = useState(false);
    const [cancelHandler, setCancelHandler] = useState<AbortController | null>(null);
    const [curError, setCurError] = useState<string | null>(null);
    const [successMsg, setSuccessMsg] = useState<string | null>(null);
    const [showUploadModal, setShowUploadModal] = useState(false);
    const [selectedInputVariable, setSelectedInputVariable] = useState<string | undefined>();
    const tooltipMessage = 'To change!';

    // State for Showing Optional Variables
    const [showOptional, setShowOptional] = useState(false);
    // Function to handle showing optional variables
    function handleToggleOptional() {
        setShowOptional(!showOptional);
    }

    function clearForm() {
        // console.log("Before", data);
        // console.log("After", {...emptyExtractorDto, variables: {}});
        setData({ ...emptyExtractorDto, variables: {} });
    }

    useEffect(() => {
        if (props.selectedExtractor === undefined) {
            return;
        }
        clearForm();
    }, [props.activeKey]);

    const [showToast, setShowToast] = useState(false);
    // Function to toggle the toast
    function toggleShowToast() {
        setShowToast((value) => {
            return !value;
        });
    }

    async function handleRun() {
        console.log('Handling Run');
        // Setting up key in data from selected extractor
        data.key = props.selectedExtractor.key;
        console.log(data);
        setSuccessMsg(null);
        setCurError(null);
        setShowToast(false);
        setAwaiting(true);
        await handleLaunchExtractor(data, setSuccessMsg, setCurError, setCancelHandler);
        setShowToast(true);
        setAwaiting(false);
        clearForm();
    }

    function isValidInput(key: string, value: string): boolean {
        return key !== undefined && key.length !== 0 && value !== undefined && value.length !== 0;
    }

    function isValidForm(): boolean {
        var isValid = true;

        // Getting all required variables
        const requiredVariables = getRequiredExtractorVariables(props.selectedExtractor.variables);

        // Looping through all required variables
        for (var i = 0; i < requiredVariables.length; i++) {
            const variable = requiredVariables[i];

            // Checking if the variable is valid
            if (!isValidInput(variable.name, data.variables[variable.name])) {
                isValid = false;
            }
        }

        // Getting all optional variables
        const optionalVariables = getOptionalExtractorVariables(props.selectedExtractor.variables);

        // Looping through all optional variables and checking if the ones that are filled out are valid
        for (var i = 0; i < optionalVariables.length; i++) {
            const variable = optionalVariables[i];

            // Checking if the variable is entered
            if (Object.keys(data.variables).includes(variable.name)) {
                // Checking if the variable is valid
                if (!isValidInput(variable.name, data.variables[variable.name])) {
                    isValid = false;
                }
            }
        }
        return isValid;
    }

    // Checking if the entered values pass input validation
    useEffect(() => {
        // Checking if modal is open
        if (props.activeKey !== 'Run') {
            return;
        }
        const isValid = isValidForm();
        setDataValid(isValid);
    }, [data]);

    function handleDataChange(event: any) {
        const name = event.target.name;
        const value = event.target.type === 'checkbox' ? event.target.checked : event.target.value;

        // Create a copy of the variables array
        const updatedVariables = data.variables;

        // Check if value is empty
        if (value === '' && name in updatedVariables) {
            // Remove the variable from the array
            delete updatedVariables[name];
        } else {
            // Update the name property of the specified variable
            updatedVariables[name] = value;
        }

        // Update the state with the new variables array
        setData({
            ...data,
            variables: updatedVariables,
        });
    }

    function handleUpload(variableName: string) {
        // Open the upload Modal
        setSelectedInputVariable(variableName);
        setSelectedKey(props.selectedExtractor.key);
        setShowUploadModal(true);
    }

    function handleFileSelection(filename: string | undefined, variableName: string | undefined) {
        console.log('handleFileSelection:', filename, variableName);

        // If the variableName is defined but the filename is not, then remove the variable from the variables array
        if (variableName !== undefined && filename === undefined) {
            const updatedVariables = data.variables;
            delete updatedVariables[variableName];
            setData({
                ...data,
                variables: updatedVariables,
            });
        }
        // If the variableName and the file name are not empty, then set the value
        else if (variableName !== undefined && filename !== undefined) {
            const updatedVariables = data.variables;
            updatedVariables[variableName] = filename;
            setData({
                ...data,
                variables: updatedVariables,
            });
        }
    }

    return (
        <>
            <div className="d-flex flex-column gap-4 p-3">
                <div>
                    <div className="fs-3 d-flex justify-content-between align-items-end">
                        Enter Parameters
                        <Button
                            onClick={handleToggleOptional}
                            className={`bg-transparent text-dark rounded-0 border-0 opacity-50 px-0`}
                        >
                            {showOptional ? 'Hide Optional Variables' : 'Show Optional Variables'}
                        </Button>
                    </div>
                    <hr />
                    <Form className="d-flex flex-column gap-3">
                        {/* Loop for input files */}
                        <div className="d-flex flex-column justify-content-center align-items-center gap-3 d-inline-block text-truncate">
                            {props.selectedExtractor !== undefined &&
                                getInputExtractorVariables(props.selectedExtractor.variables).map(
                                    (variable: ExtractorVariable, index: number) => (
                                        <Ratio aspectRatio={1 / 4} key={index}>
                                            <Button
                                                className={`btn-light text-secondary ${
                                                    variable.required ? '' : 'opacity-75'
                                                } rounded border-0 shadow-sm`}
                                                onClick={() => handleUpload(variable.name)}
                                            >
                                                {`${variable.name}${variable.required ? '' : ' (optional)'}: ${
                                                    Object.keys(data.variables).includes(variable.name)
                                                        ? data.variables[variable.name]
                                                        : 'Select/Upload file'
                                                }`}
                                            </Button>
                                        </Ratio>
                                    ),
                                )}
                        </div>
                        {/* Loop for required variables */}
                        <div className="d-flex flex-column justify-content-between gap-3">
                            {props.selectedExtractor !== undefined &&
                                getRequiredExtractorVariables(props.selectedExtractor.variables).map(
                                    (variable: ExtractorVariable, index: number) => (
                                        <Form.Group
                                            className={`flex-grow-1 ${variable.required ? '' : 'opacity-25'}`}
                                            key={index}
                                        >
                                            <FloatingLabel
                                                label={`${variable.name}${variable.required ? '' : ' (optional)'}`}
                                            >
                                                <Form.Control
                                                    name={variable.name}
                                                    type="text"
                                                    placeholder={'Enter ' + variable.name}
                                                    onChange={handleDataChange}
                                                    className="rounded-0"
                                                />
                                                <Form.Text className="text-muted"></Form.Text>
                                            </FloatingLabel>
                                        </Form.Group>
                                    ),
                                )}
                        </div>
                        {/* Loop for optional variables */}
                        <div className="d-flex flex-column justify-content-between gap-3">
                            {props.selectedExtractor !== undefined &&
                                getOptionalExtractorVariables(props.selectedExtractor.variables).map(
                                    (variable: ExtractorVariable, index: number) => (
                                        <Collapse className="flex-grow-1" in={showOptional} key={index}>
                                            <Form.Group className={`${variable.required ? '' : 'opacity-75'}`}>
                                                {/* <Form.Label>{extractor.name}</Form.Label> */}
                                                <FloatingLabel
                                                    label={`${variable.name}${variable.required ? '' : ' (optional)'}`}
                                                >
                                                    <Form.Control
                                                        name={variable.name}
                                                        type="text"
                                                        placeholder={'Enter ' + variable.name}
                                                        onChange={handleDataChange}
                                                        className="rounded-0"
                                                    />
                                                    {variable.description && (
                                                        <OverlayTrigger
                                                            placement="top"
                                                            overlay={
                                                                <Tooltip id={`tooltip-${variable.name}`}>
                                                                    {variable.description}
                                                                </Tooltip>
                                                            }
                                                        >
                                                            <div className="bg-white position-absolute m-2 p-2 top-0 end-0 cursor-pointer">
                                                                <InfoIcon size={16} />
                                                            </div>
                                                        </OverlayTrigger>
                                                    )}
                                                    {variable.example && (
                                                        <Form.Text className="text-muted">
                                                            Example(s): {variable.example}
                                                        </Form.Text>
                                                    )}
                                                </FloatingLabel>
                                            </Form.Group>
                                        </Collapse>
                                    ),
                                )}
                        </div>
                    </Form>
                </div>
            </div>
            <div className="d-flex justify-content-end">
                <Button
                    onClick={handleRun}
                    className={`bg-${awaiting ? 'secondary disabled' : 'main-1 '} ${
                        dataValid ? '' : 'disabled'
                    } fs-6 rounded-pill border-0 modal-button-width`}
                >
                    {awaiting && <Spinner animation="border" className="w-10" size="sm" />}
                    {!awaiting && <>Deploy</>}
                </Button>
            </div>
            <ToastContainer containerPosition="fixed" className="m-4" position="top-center">
                <Toast
                    show={showToast}
                    onClose={toggleShowToast}
                    bg={`${successMsg !== null ? 'success' : 'danger'}`}
                    autohide
                >
                    <Toast.Header>
                        <strong className="me-auto">
                            {successMsg !== null && <>{successMsg}</>}
                            {curError !== null && <>{curError}</>}
                        </strong>
                        <small className="text-muted">just now</small>
                    </Toast.Header>
                </Toast>
            </ToastContainer>
            <UploadFile
                show={showUploadModal}
                setShow={setShowUploadModal}
                selectedKey={selectedKey}
                inputVariableName={selectedInputVariable}
                onFileSelection={handleFileSelection}
            />
        </>
    );
}

export default RunExtractor;
