import axios from 'axios';
import { Buffer } from 'buffer';

const BASE_URL = process.env.REACT_APP_API_URL;

export interface User {
    isActive: boolean;
    name: string;
    email: string;
    role: string;
}

export var emptyUser: User = {
    isActive: false,
    name: '',
    email: '',
    role: '',
};

export async function adminLogin(email: string, password: string, setCurError: any) {
    let apiRes: any = null;

    await axios
        .post(BASE_URL + `/api/v1/auth/admin/email/login`, { email: email, password: password })
        .then((response) => {
            apiRes = response;
        })
        .catch((error) => {
            setCurError(error.response.data.errors.message);
            apiRes = error;
        });

    if (apiRes.status === 200 && apiRes.data.token !== null) {
        localStorage.setItem('token', apiRes.data.token);
    }
}

export async function tokenCheck(token: string | null) {
    if (token === null) {
        return emptyUser;
    }

    let apiRes: any = null;
    const config = {
        headers: { Authorization: `Bearer ${token}` },
    };

    await axios
        .get(BASE_URL + `/api/v1/auth/me`, config)
        .then((response) => {
            apiRes = response;
        })
        .catch((error) => {
            apiRes = error;
        });

    console.log(apiRes);

    if (apiRes.status === 200) {
        let tempUser: User = emptyUser;
        const data = apiRes.data;

        tempUser.isActive = data.isActive;
        tempUser.role = data.admin;
        tempUser.email = data.email;
        tempUser.name = data.name;

        return tempUser;
    } else {
        return emptyUser;
    }
}

export async function googleAuth(token: string, setCurError: any) {
    let apiRes: any = null;

    await axios
        .post(BASE_URL + `/api/v1/auth/google/login`, { credential: token })
        .then((response) => {
            apiRes = response;
        })
        .catch((error) => {
            apiRes = error;

            try {
                setCurError(error.response.data.errors.message);
            } catch (error) {
                setCurError('Something went wrong');
            }
        });

    if (apiRes.status === 200 && apiRes.data.token && apiRes.data.token !== null) {
        localStorage.setItem('token', apiRes.data.token);
    }
}

export function parseJwt(token: string) {
    return JSON.parse(Buffer.from(token.split('.')[1], 'base64').toString());
}
