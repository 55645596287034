import moment from 'moment';

let oneDayOffsetMilliseconds = 86400000;
let oneDayOffsetSeconds = 86400;

export function stringToTimestamp(date: string) {
    return moment(date).unix();
}

export function stringToDate(date: string) {
    return timestampToDate(moment(date).unix());
}

export function stringToShortDate(date: string) {
    return timestampToShortDate(moment(date).unix());
}

export function timestampToDate(timestamp: number) {
    if (timestamp === null) {
        return '';
    }

    let timestampFix = timestamp * 1000;

    if (isToday(timestamp)) {
        return 'Today at ' + moment(timestampFix).format('h:mm A');
    } else if (isYesterday(timestamp)) {
        return 'Yesterday at ' + moment(timestampFix).format('h:mm A');
    } else if (isTomorrow(timestamp)) {
        return 'Tomorrow at ' + moment(timestampFix).format('h:mm A');
    }
    return moment(timestampFix).format('ddd h:mm A, MMM D, YYYY');
}

export function timestampToShortDate(timestamp: number) {
    if (timestamp === null) {
        return '';
    }

    let timestampFix = timestamp * 1000;
    return moment(timestampFix).format('MMM D');
}

export function dateToTimestamp(date: Date, dayOffset: number = 0) {
    return date.getTime() / 1000 + dayOffset * oneDayOffsetSeconds;
}

export function isToday(timestamp: number) {
    let currentTimestamp = Date.now();
    let timestampFix = timestamp * 1000;

    return moment(timestampFix).format('MMM Do YYYY') === moment(currentTimestamp).format('MMM Do YYYY');
}

export function isYesterday(timestamp: number) {
    let currentTimestamp = Date.now();
    let timestampFix = timestamp * 1000;

    return (
        moment(timestampFix).format('MMM Do YYYY') ===
        moment(currentTimestamp - oneDayOffsetMilliseconds).format('MMM Do YYYY')
    );
}

export function isTomorrow(timestamp: number) {
    let currentTimestamp = Date.now();
    let timestampFix = timestamp * 1000;

    return (
        moment(timestampFix).format('MMM Do YYYY') ===
        moment(currentTimestamp + oneDayOffsetMilliseconds).format('MMM Do YYYY')
    );
}

export function isNotNear(timestamp: number) {
    return !isToday(timestamp) && !isYesterday(timestamp) && !isTomorrow(timestamp);
}
