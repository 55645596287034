import { useState, useEffect } from 'react';
import { Route, Routes } from 'react-router-dom';
import './Extractors.css';

import { Container, Button, Spinner, Accordion, Toast, ToastContainer } from 'react-bootstrap';

import { RepoDeletedIcon, MarkGithubIcon, ChevronRightIcon } from '@primer/octicons-react';

import { syncExtractor } from '../../../services/jobs.service';

import { Extractor, getExtractorData } from '../../../services/jobs.service';
import ExtractorDetails from '../../../Components/ExtractorDetails/ExtractorDetails';
import { Link } from 'react-router-dom';
import { stringToShortDate } from '../../../services/date.service';

function Extractors(props: any) {
    // Utils
    document.title = `Extractors` + process.env.REACT_APP_TITLE_POSTFIX;

    const [successMsg, setSuccessMsg] = useState<string | null>(null);
    const [successDescription, setSuccessDescription] = useState<string | null>(null);
    const [errorMsg, setErrorMsg] = useState<string | null>(null);
    const [awaiting, setAwaiting] = useState(false);
    const [cancelHandler, setCancelHandler] = useState<AbortController | null>(null);

    // Extractors Data
    const [extractors, setExtractors] = useState<Extractor[] | undefined>(undefined);

    useEffect(() => {
        props.setActive('extractors');
        fetchExtractorData();
    }, []);

    function fetchExtractorData() {
        console.log(extractors);

        getExtractorData(setCancelHandler).then((res: Extractor[]) => {
            setExtractors(res);
        });
    }

    // SYNC Functionality
    const [showSyncToast, setShowSyncToast] = useState(false);

    function toggleSyncToast() {
        setShowSyncToast((value) => {
            return !value;
        });
    }

    function handleSync() {
        setAwaiting(true);
        setShowSyncToast(false); // Making sure that the toast is hidden
        setSuccessMsg('Sending request to synchronize');
        setSuccessDescription('Please wait...');
        setErrorMsg(null);

        toggleSyncToast();
        syncExtractor(setSuccessMsg, setSuccessDescription, setErrorMsg, setCancelHandler).finally(() => {
            setAwaiting(false);

            // Refreshing extractors
            // TODO: Need to figure out if sync was a success
            // Only need to refresh if sync was a success
            setExtractors(undefined);
            fetchExtractorData();
        });
    }

    return (
        <Routes>
            <Route
                path="/"
                element={
                    <>
                        <Container fluid className="my-3">
                            <Button
                                className={`bg-transparent text-main border-0 rounded-pill fs-7 p-0 ${
                                    awaiting ? 'disabled' : ''
                                }`}
                                onClick={handleSync}
                            >
                                Synchronize
                            </Button>
                        </Container>

                        {extractors === undefined && (
                            <Container fluid className="bg-white mt-3 rounded-3 shadow-sm pb-0">
                                <div className="d-flex justify-content-center gap-4 py-4 opacity-75">
                                    <Spinner animation="grow" variant="primary" size="sm" />
                                    <Spinner animation="grow" variant="primary" size="sm" />
                                    <Spinner animation="grow" variant="primary" size="sm" />
                                </div>
                            </Container>
                        )}
                        {extractors !== undefined && extractors.length === 0 && (
                            <Container fluid className="bg-white mt-3 rounded-3 shadow-sm pb-0">
                                <div className="d-flex justify-content-center align-item-center gap-3 py-4 opacity-75">
                                    <RepoDeletedIcon size={24} />
                                    No extractors found! Try synchronizing.
                                </div>
                            </Container>
                        )}
                        {extractors !== undefined && extractors.length > 0 && (
                            <div className="btn-group-vertical w-100 bg-white rounded-3 shadow-sm">
                                {extractors.map((extractor) => (
                                    <Link
                                        to={`${encodeURIComponent(extractor.key)}`}
                                        className={`btn ${
                                            extractor.variables ? 'btn-white' : 'btn-danger'
                                        } d-flex justify-content-between align-items-center fs-6 p-3`}
                                        key={extractor.key}
                                    >
                                        <div className="d-flex flex-wrap align-items-center text-break gap-3">
                                            <span>{extractor.name}</span>
                                            {extractor.variables && (
                                                <span>
                                                    <strong>Parameters:</strong> {extractor.variables.length}
                                                </span>
                                            )}
                                            <span>
                                                <strong>Last Updated:</strong> {stringToShortDate(extractor.updatedAt)}
                                            </span>
                                        </div>
                                        <ChevronRightIcon size={24} />
                                    </Link>
                                ))}
                            </div>
                        )}
                        <ToastContainer containerPosition="fixed" className="m-4" position="top-center">
                            <Toast show={showSyncToast} onClose={toggleSyncToast}>
                                <Toast.Header>
                                    <MarkGithubIcon size={16} />
                                    <strong className="me-auto ms-2">
                                        {successMsg !== null && <>{successMsg}</>}
                                        {errorMsg !== null && <>{errorMsg}</>}
                                    </strong>
                                    <small className="text-muted">just now</small>
                                </Toast.Header>
                                <Toast.Body>
                                    {successMsg !== null && successDescription !== null && <>{successDescription}</>}
                                    {errorMsg !== null && (
                                        <>There seems to be a problem with the process. Please try again later.</>
                                    )}
                                </Toast.Body>
                            </Toast>
                        </ToastContainer>
                    </>
                }
            />
            <Route path=":extractorKey" element={<ExtractorDetails extractors={extractors} />} />
        </Routes>
    );
}

export default Extractors;
