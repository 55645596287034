import { useEffect, useState } from 'react';
import { DownloadIcon, RepoDeletedIcon } from '@primer/octicons-react';
import { Button, Container, Nav, Spinner, ToastContainer, Toast } from 'react-bootstrap';
import { Extractor, downloadExtractorSampleOutput, downloadExtractorSampleInput } from '../../services/jobs.service';
import RunExtractor from '../Modals/RunExtractor';
import { useParams } from 'react-router-dom';
import ExtractorBreadcrumb from './ExtractorBreadcrumb';
import ExtractorVariables from './ExtractorVariables';
import ViewHistory from './ExtractorHistory';

function ExtractorDetails(props: { extractors: Extractor[] | undefined }) {
    // Routing
    const [extractor, setExtractor] = useState<Extractor | undefined>(undefined);
    const { extractorKey } = useParams();

    useEffect(() => {
        // Checking if extractorKey is undefined
        if (extractorKey === undefined) {
            return;
        }
        setExtractor(getExtractorFromArray());
    }, [props.extractors]);

    function getExtractorFromArray() {
        console.log('ExtractorKey', extractorKey);

        if (props.extractors === undefined || extractorKey === undefined) {
            return undefined;
        }

        for (let i = 0; i < props.extractors.length; i++) {
            if (props.extractors[i].key === extractorKey) {
                return props.extractors[i];
            }
        }
        return undefined;
    }

    // NAVIGATION
    const navList = ['Overview', 'History', 'Run'];
    const [activeKey, setActiveKey] = useState<string>(navList[0]);

    function handleNavigationSelect(eventKey: string | null) {
        if (eventKey === null) {
            return;
        }
        setActiveKey(eventKey);
        console.log(eventKey);
    }

    function extractorsExist() {
        return props.extractors !== undefined && props.extractors.length > 0;
    }

    const [showToast, setShowToast] = useState(false);
    // Function to toggle the toast
    function toggleShowToast() {
        setShowToast((value) => {
            return !value;
        });
    }

    // SAMPLE DOWNLOAD
    const [awaitingSampleOutput, setAwaitingSampleOutput] = useState(false);
    const [successMsgSampleOutput, setSuccessMsgSampleOutput] = useState<string | null>(null);
    const [errorMsgSampleOutput, setErrorMsgSampleOutput] = useState<string | null>(null);
    const [awaitingSampleInput, setAwaitingSampleInput] = useState(false);
    const [successMsgSampleInput, setSuccessMsgSampleInput] = useState<string | null>(null);
    const [errorMsgSampleInput, setErrorMsgSampleInput] = useState<string | null>(null);
    const [cancelHandler, setCancelHandler] = useState<AbortController | null>(null);

    async function handleDownloadSampleOutput() {
        if (extractor === undefined) {
            return;
        }
        setSuccessMsgSampleInput(null);
        setErrorMsgSampleInput(null);
        setSuccessMsgSampleOutput(null);
        setErrorMsgSampleOutput(null);

        setAwaitingSampleOutput(true);
        setShowToast(false);
        await downloadExtractorSampleOutput(extractor.key, setSuccessMsgSampleOutput, setErrorMsgSampleOutput, setCancelHandler);
        setAwaitingSampleOutput(false);
        setShowToast(true);
    }

    async function handleDownloadSampleInput() {
        if (extractor === undefined) {
            return;
        }
        setSuccessMsgSampleInput(null);
        setErrorMsgSampleInput(null);
        setSuccessMsgSampleOutput(null);
        setErrorMsgSampleOutput(null);

        setShowToast(false);
        setAwaitingSampleInput(true);
        await downloadExtractorSampleInput(extractor.key, setSuccessMsgSampleInput, setErrorMsgSampleInput, setCancelHandler);
        setAwaitingSampleInput(false);
        setShowToast(true);
    }

    return (
        <>
            <div className="mt-3">
                {props.extractors === undefined && (
                    <div className="d-flex justify-content-center gap-4 py-4 opacity-75">
                        <Spinner animation="grow" variant="primary" size="sm" />
                        <Spinner animation="grow" variant="primary" size="sm" />
                        <Spinner animation="grow" variant="primary" size="sm" />
                    </div>
                )}

                {props.extractors !== undefined && props.extractors.length === 0 && (
                    <div className="d-flex justify-content-center align-item-center gap-3 py-4 opacity-75">
                        <RepoDeletedIcon size={24} />
                        No extractors found! Try synchronizing.
                    </div>
                )}

                {extractorsExist() && extractor === undefined && (
                    <div className="d-flex justify-content-center align-item-center gap-3 py-4 opacity-75">
                        <RepoDeletedIcon size={24} />
                        No extractor selected!
                    </div>
                )}
                {extractorsExist() && extractor !== undefined && (
                    <>
                        <ExtractorBreadcrumb name={extractor.name} />
                        {/* Only showing navigation if it is a valid extractor */}
                        {extractor.variables && extractor.variables.length > 0 && (
                            <div className="d-flex flex-wrap align-items-center my-3 gap-2">
                                <Nav variant="pills" activeKey={activeKey} onSelect={handleNavigationSelect}>
                                    {navList.map((navItem) => (
                                        <Nav.Item key={navItem}>
                                            <Nav.Link
                                                eventKey={navItem}
                                                className={`rounded-pill py-1 ${activeKey !== navItem ? 'text-black-50 fs-7' : 'fs-6'
                                                    }`}
                                            >
                                                {navItem}
                                            </Nav.Link>
                                        </Nav.Item>
                                    ))}
                                </Nav>
                                {/* <Link
                            to="/dashboard/extractors"
                            className="text-black-50 fs-7 d-flex text-decoration-none align-items-center"
                        >
                            <ChevronLeftIcon size={24} />
                            <span>Back</span>
                        </Link> */}
                            </div>
                        )}
                        {activeKey === 'Overview' && (
                            <Container fluid className="bg-white mt-3 rounded-3 shadow-sm py-3">
                                <div className="d-flex flex-column gap-4 p-3">
                                    <div>
                                        <div className="d-flex flex-wrap justify-content-between align-items-center">
                                            <div className="h5">Description</div>
                                            <div className="d-flex gap-2">
                                                <Button
                                                    className="btn-secondary d-flex gap-2 justify-content-center align-items-center fs-8 large-button-width text-nowrap"
                                                    onClick={handleDownloadSampleInput}
                                                >
                                                    {awaitingSampleInput ? (
                                                        <Spinner
                                                            animation="border"
                                                            variant="light"
                                                            size="sm"
                                                            className="me-2"
                                                        />
                                                    ) : (
                                                        <>
                                                            <DownloadIcon size={16} />
                                                            Sample Input
                                                        </>
                                                    )}
                                                </Button>
                                                {extractor.sampleOutput && (
                                                    <Button
                                                        className="btn-secondary d-flex gap-2 justify-content-center align-items-center fs-8 large-button-width text-nowrap"
                                                        onClick={handleDownloadSampleOutput}
                                                    >
                                                        {awaitingSampleOutput ? (
                                                            <Spinner
                                                                animation="border"
                                                                variant="light"
                                                                size="sm"
                                                                className="me-2"
                                                            />
                                                        ) : (
                                                            <>
                                                                <DownloadIcon size={16} />
                                                                Sample Output
                                                            </>
                                                        )}
                                                    </Button>
                                                )}
                                            </div>
                                        </div>
                                        <hr />
                                        <span className="fs-6">
                                            {extractor.description
                                                ? String(extractor.description)
                                                : 'No description found for this extractor.'}
                                        </span>
                                    </div>
                                    <div>
                                        <div className="h5">Parameters</div>
                                        <hr />
                                        <ExtractorVariables variables={extractor.variables} />
                                    </div>
                                </div>
                            </Container>
                        )}
                        {activeKey === 'History' && (
                            <Container>
                                <ViewHistory extractorKey={extractor.key} />
                            </Container>
                        )}
                        {activeKey === 'Run' && extractor.variables && extractor.variables.length > 0 && (
                            <Container className="bg-white mt-3 rounded-3 shadow-sm py-3">
                                <RunExtractor activeKey={activeKey} selectedExtractor={extractor} />
                            </Container>
                        )}
                    </>
                )}
            </div>
            <ToastContainer containerPosition="fixed" className="m-4" position="top-center">
                <Toast
                    show={showToast}
                    onClose={toggleShowToast}
                    bg={`${successMsgSampleOutput !== null || successMsgSampleInput !== null ? 'success' : 'danger'}`}
                    autohide
                >
                    <Toast.Header>
                        <strong className="me-auto">
                            {successMsgSampleInput !== null && <>{successMsgSampleInput}</>}
                            {errorMsgSampleInput !== null && <>{errorMsgSampleInput}</>}
                            {successMsgSampleOutput !== null && <>{successMsgSampleOutput}</>}
                            {errorMsgSampleOutput !== null && <>{errorMsgSampleOutput}</>}
                        </strong>
                        <small className="text-muted">just now</small>
                    </Toast.Header>
                </Toast>
            </ToastContainer>
        </>
    );
}

export default ExtractorDetails;
