import React from 'react';
import { ProgressBar } from 'react-bootstrap';

function LiveStats(props: any) {
    const keysToDisplay = {
        progress_percentage: 'Progress (%)',
        item_scraped_count: 'Item Scraped Count',
        'downloader/request_count': 'Request Count',
        'downloader/response_count': 'Response Count',
        'custom/errors_count': 'Error Count',
        'downloader/exception_count': 'Exception Count',
        'downloader/request_bytes': 'Request Bytes',
        'downloader/response_bytes': 'Response Bytes',
        'retry/count': 'Retry Count',
        'httpcache/firsthand': 'Cache First Hand',
        'httpcache/hit': 'Cache Hit',
        'httpcache/miss': 'Cache Miss',
        'httpcache/store': 'Cache Store',
        'memusage/max': 'Max Memory Usage',
        'log_count/ERROR': 'Log Errors',
        update_time: 'Last Updated',
    };

    function statParse(value: string | number, key: string): string {
        if (key === 'memusage/max' && typeof value === 'number') {
            return `${(value / 1024 / 1024).toFixed(2)} MB`;
        } else if (key === 'downloader/request_bytes' && typeof value === 'number') {
            return `${(value / 1024 / 1024).toFixed(2)} MB`;
        } else if (key === 'downloader/response_bytes' && typeof value === 'number') {
            return `${(value / 1024 / 1024).toFixed(2)} MB`;
        } else if (key === 'progress_percentage' && typeof value === 'string') {
            return value.replace('%', '');
        } else if (key === 'update_time' && typeof value === 'string') {
            // Parse Date UTC
            let date = new Date(value);

            // Convert UTC to local time
            date.setHours(date.getHours() - date.getTimezoneOffset() / 60);

            // Find diff in seconds
            let diff = Math.abs((new Date().getTime() - date.getTime()) / 1000);

            // If diff is less than 60 seconds, return 'Just Now'
            if (diff < 60) {
                return 'Just Now';
            }

            // If diff is less than 60 minutes, return minutes
            if (diff < 60 * 60) {
                return `${Math.floor(diff / 60)} minutes ago`;
            }

            // If diff is less than 24 hours, return hours
            if (diff < 60 * 60 * 24) {
                return `${Math.floor(diff / 60 / 60)} hours ago`;
            }

            return `${Math.floor(diff / 60 / 60 / 24)} days ago`;
        } else if (typeof value === 'number') {
            return value.toLocaleString();
        } else {
            return value;
        }
    }

    function detectUrl(value: string): boolean {
        try {
            new URL(value);
            return true;
        } catch (_) {
            return false;
        }
    }

    return (
        <>
            <div className=" my-3">
                {props.type === 'crawl' && (
                    <ProgressBar
                        now={parseFloat(
                            statParse(
                                props.liveStats['progress_percentage' as keyof typeof props.liveStats],
                                'progress_percentage',
                            ),
                        )}
                        label={`${props.liveStats['progress_percentage' as keyof typeof props.liveStats]}`}
                    />
                )}
            </div>
            <div className="row justify-content-center">
                <div className={`col-${props.xs} col-sm-${props.sm} col-md-${props.md}`}>
                    <div className="fw-bold">Stats</div>
                    <div className="table-responsive">
                        <table className="table table-hover">
                            <tbody>
                                {props.type === 'crawl' &&
                                    Object.keys(keysToDisplay).map((key: string) => (
                                        <tr key={key}>
                                            <td>{keysToDisplay[key as keyof typeof keysToDisplay]}</td>
                                            <td>
                                                {statParse(props.liveStats[key as keyof typeof props.liveStats], key)}
                                            </td>
                                        </tr>
                                    ))}
                                {props.type !== 'crawl' &&
                                    Object.keys(props.liveStats).map((key: string) => (
                                        <tr key={key}>
                                            {detectUrl(props.liveStats[key as keyof typeof props.liveStats]) && (
                                                <td
                                                    colSpan={2}
                                                    className="p-0"
                                                    onClick={() =>
                                                        window.open(
                                                            props.liveStats[key as keyof typeof props.liveStats],
                                                            '_blank',
                                                        )
                                                    }
                                                >
                                                    <div className="btn btn-transparent w-100 p-2 m-0 h-100 text-start">
                                                        <a
                                                            href={props.liveStats[key as keyof typeof props.liveStats]}
                                                            className=""
                                                            target="_blank"
                                                            rel="noreferrer"
                                                        >
                                                            {key}
                                                        </a>
                                                    </div>
                                                </td>
                                            )}
                                            {!detectUrl(props.liveStats[key as keyof typeof props.liveStats]) && (
                                                <>
                                                    <td>{key}</td>
                                                    <td>
                                                        {statParse(
                                                            props.liveStats[key as keyof typeof props.liveStats],
                                                            key,
                                                        )}
                                                    </td>
                                                </>
                                            )}
                                        </tr>
                                    ))}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </>
    );
}

export default LiveStats;
