import { Offcanvas, Accordion, Container, OverlayTrigger, Tooltip, Button, Spinner, ToastContainer, Toast } from 'react-bootstrap';
import { timestampToDate } from '../../services/date.service';
import {
    CheckCircleFillIcon,
    CloudOfflineIcon,
    XCircleFillIcon,
    AlertFillIcon,
    IssueReopenedIcon,
    HistoryIcon,
} from '@primer/octicons-react';
import { copyToClipboard, generatePackageGithubUrl, workflowHistory, retryJob, getJobs, markTaskAsDone } from '../../services/jobs.service';
import LiveStats from '../LiveStats/LiveStats';
import { useState } from 'react';
import './Offcanvas.css';

function FailedOffcanvas(props: any) {
    const [copyTooltip, setCopyTooltip] = useState('Copy to clipboard');
    const [successMsg, setSuccessMsg] = useState<string | null>(null);
    const [errorMsg, setErrorMsg] = useState<string | null>(null);
    const [awaiting, setAwaiting] = useState(false);
    const [cancelHandler, setCancelHandler] = useState<AbortController | null>(null);
    const [markDoneAwaiting, setMarkDoneAwaiting] = useState(false);
    const [markDoneSuccessMsg, setMarkDoneSuccessMsg] = useState<string | null>(null);
    const [markDoneErrorMsg, setMarkDoneErrorMsg] = useState<string | null>(null);
    const [showToast, setShowToast] = useState(false);

    async function handleRetry(workflowKey: string, jobId: string) {
        setAwaiting(true);
        setSuccessMsg(null);
        setErrorMsg(null);

        let schedule_name: string | undefined = undefined;
        let schedules = await getJobs(1, undefined, undefined, undefined, undefined, setCancelHandler);

        for (let i = 0; i < schedules.length; i++) {
            if (schedules[i].workflowKey === workflowKey) {
                schedule_name = schedules[i].name;
                break;
            }
        }

        await retryJob(workflowKey, jobId, schedule_name, undefined, setSuccessMsg, setErrorMsg, setCancelHandler);

        setAwaiting(false);
    }

    function toggleToastShow() {
        setShowToast((value) => {
            return !value;
        });
    }

    async function handleMarkAsDone(workflowName: string, workflowKey: string, jobId: string, taskName: string) {
        setMarkDoneAwaiting(true);
        setMarkDoneSuccessMsg(null);
        setMarkDoneErrorMsg(null);

        await markTaskAsDone(workflowKey, workflowName, jobId, taskName, setMarkDoneSuccessMsg, setMarkDoneErrorMsg, setCancelHandler);

        setMarkDoneAwaiting(false);
        setShowToast(true);

        // updating the execution.status to SUCCESS in the summary object to reflect the change in the UI without refreshing
        props.summary.failed.forEach((historyItem: workflowHistory) => {
            if (historyItem.jobId === jobId) {
                historyItem.taskExecutions.forEach((execution) => {
                    if (execution.name === taskName) {
                        execution.status = 'SUCCESS';
                    }
                });
            }
        });
    }

    return (
        <>
            <Offcanvas show={props.failedShow} onHide={props.handleFailedToggle} placement={'end'}>
                <Offcanvas.Header closeButton>
                    <Offcanvas.Title>Failed Jobs</Offcanvas.Title>
                </Offcanvas.Header>
                <Offcanvas.Body>
                    {props.summary !== null && props.summary.failed.length === 0 && (
                        <div className="d-flex justify-content-center gap-2">
                            <CloudOfflineIcon size={20} />
                            No Records Found
                        </div>
                    )}
                    {props.summary !== null && props.summary.failed.length > 0 && (
                        <Accordion flush
                            onSelect={() => { setSuccessMsg(null); setErrorMsg(null); setMarkDoneSuccessMsg(null); setMarkDoneErrorMsg(null) }}>
                            {props.summary.failed.map((historyItem: workflowHistory) => (
                                <Accordion.Item
                                    eventKey={`${historyItem.startedAt.toString()}-${historyItem.jobId.toString()}`}
                                    key={`${historyItem.startedAt.toString()}-${historyItem.jobId.toString()}`}
                                >
                                    <Accordion.Header>
                                        <Container fluid>
                                            <div className="d-flex gap-2 text-break align-items-center">
                                                <span className={`badge rounded-pill text-bg-primary`}>
                                                    {timestampToDate(historyItem.startedAt)}
                                                </span>
                                                {historyItem.workflowName}
                                            </div>
                                        </Container>
                                    </Accordion.Header>
                                    <Accordion.Body>
                                        <Accordion>
                                            <div className="d-flex gap-1 mb-2">
                                                <OverlayTrigger
                                                    placement="left"
                                                    delay={{ show: 250, hide: 250 }}
                                                    overlay={<Tooltip>Open on Github</Tooltip>}
                                                >
                                                    <a
                                                        href={generatePackageGithubUrl(historyItem.workflowKey)}
                                                        className="fs-9 fw-light text-bg-secondary badge rounded-pill py-1 border-0 text-decoration-none"
                                                        target="_bank"
                                                    >
                                                        {/* workflow key  */}
                                                        {historyItem.workflowKey.split('::')[1]}
                                                    </a>
                                                </OverlayTrigger>

                                                <OverlayTrigger
                                                    placement="right"
                                                    delay={{ show: 250, hide: 250 }}
                                                    overlay={<Tooltip>{copyTooltip}</Tooltip>}
                                                >
                                                    <button
                                                        className="fs-9 fw-light text-bg-secondary badge rounded-pill py-1 border-0"
                                                        onClick={() => {
                                                            setCopyTooltip('Copied!');
                                                            copyToClipboard(historyItem.jobId);

                                                            setTimeout(() => {
                                                                setCopyTooltip('Copy to clipboard');
                                                            }, 2000);
                                                        }}
                                                    >
                                                        Job ID: {historyItem.jobId}
                                                    </button>
                                                </OverlayTrigger>
                                            </div>
                                            {historyItem.taskExecutions !== undefined &&
                                                historyItem.taskExecutions !== null &&
                                                historyItem.taskExecutions.map((execution) => (
                                                    <Accordion.Item
                                                        eventKey={`${execution.startedAt.toString()}-${execution.name}`}
                                                        key={`${execution.startedAt.toString()}-${execution.name}`}
                                                    >
                                                        <Accordion.Header>
                                                            <Container fluid>
                                                                <div className="d-flex gap-2 text-break align-items-center">
                                                                    {execution.status === 'SUCCESS' && (
                                                                        <CheckCircleFillIcon
                                                                            className="text-success"
                                                                            size={24}
                                                                        />
                                                                    )}
                                                                    {execution.status === 'ERROR' && (
                                                                        <XCircleFillIcon
                                                                            className="text-danger"
                                                                            size={24}
                                                                        />
                                                                    )}
                                                                    {execution.status === 'RUNNING' && (
                                                                        <IssueReopenedIcon
                                                                            className="text-secondary rotate"
                                                                            size={24}
                                                                            fill='#7444FF'
                                                                        />
                                                                    )}
                                                                    {execution.status === 'PENDING' && (
                                                                        <HistoryIcon
                                                                            className="text-secondary"
                                                                            size={24}
                                                                        />
                                                                    )}
                                                                    {execution.status !== 'SUCCESS' &&
                                                                        execution.status !== 'ERROR' &&
                                                                        execution.status !== 'RUNNING' &&
                                                                        execution.status !== 'PENDING' && (
                                                                            <AlertFillIcon
                                                                                className="text-secondary"
                                                                                size={24}
                                                                            />
                                                                        )}
                                                                    {execution.name}
                                                                </div>
                                                            </Container>
                                                        </Accordion.Header>
                                                        <Accordion.Body>
                                                            <Container>
                                                                <div className="d-flex table-responsive">
                                                                    <table className="table table-hover">
                                                                        <tbody>
                                                                            {execution.history.map((execHistory) => (
                                                                                <tr
                                                                                    key={`${execHistory.status}-${execHistory.timestamp}`}
                                                                                >
                                                                                    <td>
                                                                                        {execHistory.status ===
                                                                                            'PENDING' && (
                                                                                                <HistoryIcon
                                                                                                    className="text-secondary"
                                                                                                    size={24}
                                                                                                />
                                                                                            )}
                                                                                        {execHistory.status ===
                                                                                            'STARTED' && (
                                                                                                <AlertFillIcon
                                                                                                    className="text-secondary"
                                                                                                    size={24}
                                                                                                />
                                                                                            )}
                                                                                        {execHistory.status ===
                                                                                            'SUCCESS' && (
                                                                                                <CheckCircleFillIcon
                                                                                                    className="text-success"
                                                                                                    size={24}
                                                                                                />
                                                                                            )}
                                                                                        {execHistory.status === 'ERROR' && (
                                                                                            <XCircleFillIcon
                                                                                                className="text-danger"
                                                                                                size={24}
                                                                                            />
                                                                                        )}
                                                                                    </td>
                                                                                    <td>{execHistory.status}</td>
                                                                                    <td>
                                                                                        <span className="fw-bold">
                                                                                            Timestamp:{' '}
                                                                                        </span>
                                                                                        {timestampToDate(
                                                                                            execHistory.timestamp,
                                                                                        )}
                                                                                    </td>
                                                                                </tr>
                                                                            ))}
                                                                        </tbody>
                                                                    </table>
                                                                </div>
                                                                {execution.status === 'ERROR' && (
                                                                    <div >
                                                                        <div className="d-flex justify-content-end gap-2 mt-2">
                                                                            <Button
                                                                                onClick={() =>
                                                                                    handleMarkAsDone(
                                                                                        historyItem.workflowName,
                                                                                        historyItem.workflowKey,
                                                                                        historyItem.jobId,
                                                                                        execution.name,
                                                                                    )
                                                                                }
                                                                                className={`bg-${markDoneAwaiting ? 'secondary' : 'success'
                                                                                    } rounded-0 border-0 modal-button-width`}
                                                                            >
                                                                                {markDoneAwaiting && (
                                                                                    <Spinner
                                                                                        animation="border"
                                                                                        className="w-10"
                                                                                        size="sm"
                                                                                    />
                                                                                )}
                                                                                {!markDoneAwaiting && <>Mark Done</>}
                                                                            </Button>
                                                                        </div>
                                                                    </div>
                                                                )}
                                                                {Object.keys(execution.liveStats).length > 0 && (
                                                                    <LiveStats
                                                                        liveStats={execution.liveStats}
                                                                        type={execution.type}
                                                                        xs={12}
                                                                        sm={12}
                                                                        md={12}
                                                                    />
                                                                )}
                                                            </Container>
                                                        </Accordion.Body>
                                                    </Accordion.Item>
                                                ))}
                                        </Accordion>
                                        {successMsg && (
                                            <div className="alert alert-success" role="alert">
                                                {successMsg}
                                            </div>
                                        )}
                                        {errorMsg && (
                                            <div className="alert alert-danger" role="alert">
                                                {errorMsg}
                                            </div>
                                        )}
                                        <div className="d-flex justify-content-end align-items-center gap-2">
                                            <Button
                                                onClick={() =>
                                                    handleRetry(historyItem.workflowKey, historyItem.jobId)
                                                }
                                                className={`bg-${awaiting ? 'secondary' : 'main-1'
                                                    } rounded-0 border-0 modal-button-width mt-2`}
                                            >
                                                {awaiting && (
                                                    <Spinner animation="border" className="w-10" size="sm" />
                                                )}
                                                {!awaiting && <>Restart Job</>}
                                            </Button>
                                        </div>
                                    </Accordion.Body>
                                </Accordion.Item>
                            ))}
                        </Accordion>
                    )}
                </Offcanvas.Body>
            </Offcanvas>
            <ToastContainer containerPosition="fixed" className="m-4" position="top-center">
                <Toast
                    show={showToast}
                    onClose={toggleToastShow}
                    bg={`${markDoneSuccessMsg !== null ? 'success' : 'danger'}`}
                    autohide
                >
                    <Toast.Header>
                        <strong className="me-auto">
                            {markDoneSuccessMsg !== null && <>{markDoneSuccessMsg}</>}
                            {markDoneErrorMsg !== null && <>{markDoneErrorMsg}</>}
                        </strong>
                        <small className="text-muted">just now</small>
                    </Toast.Header>
                </Toast>
            </ToastContainer>
        </>
    );
}

export default FailedOffcanvas;
