import { Container, Row } from 'react-bootstrap';
import SummaryItem from './SummaryItem';
import { SummaryData, getSummary, retryJob } from '../../services/jobs.service';
import { useState, useEffect } from 'react';
import RunningOffcanvas from '../Offcanvas/Running';
import FailedOffcanvas from '../Offcanvas/Failed';
import CompletedOffcanvas from '../Offcanvas/Completed';
import UpcomingOffcanvas from '../Offcanvas/Upcoming';

function Summary() {
    const [summary, setSummary] = useState<SummaryData | null>(null);
    const [runningShow, setRunningShow] = useState<boolean>(false);
    const [failedShow, setFailedShow] = useState<boolean>(false);
    const [completedShow, setCompletedShow] = useState<boolean>(false);
    const [upcomingShow, setUpcomingShow] = useState<boolean>(false);

    const [cancelHandler, setCancelHandler] = useState<AbortController | null>(null);

    const refreshTimeout = 5 * 60 * 1000; // 5 minutes

    useEffect(() => {
        fetchSummary();

        const interval = setInterval(() => {
            // Check if the tab is active
            if (document.visibilityState !== 'hidden') {
                setSummary(null);
                fetchSummary();
            } else {
                // console.log('Tab is hidden!');
            }
        }, refreshTimeout);

        return () => {
            clearInterval(interval);
        };
    }, []);

    function fetchSummary() {
        getSummary(setCancelHandler).then((res: SummaryData | null) => {
            setSummary(res);
        });
    }

    function handleRunningToggle() {
        setRunningShow((val) => {
            return !val;
        });
    }
    function handleFailedToggle() {
        setFailedShow((val) => {
            return !val;
        });
    }
    function handleCompletedToggle() {
        setCompletedShow((val) => {
            return !val;
        });
    }
    function handleUpcomingToggle() {
        setUpcomingShow((val) => {
            return !val;
        });
    }

    return (
        <Container fluid>
            <Row className="justify-content-between">
                <SummaryItem
                    title={'Running'}
                    subtitle={'Live'}
                    value={summary !== null ? summary.running.length : null}
                    color={summary !== null && summary.running.length > 0 ? `warning` : `secondary`}
                    className={'col-6 col-sm-3 col-md-3 pe-2 mb-2 mb-sm-0 mb-md-0'}
                    onClick={handleRunningToggle}
                />
                <SummaryItem
                    title={'Upcoming'}
                    subtitle={'Today'}
                    value={summary !== null ? summary.upcoming.length : null}
                    color={summary !== null && summary.upcoming.length > 0 ? `warning` : `success`}
                    className={'col-6 col-sm-3 col-md-3 pe-sm-2 pe-md-2 mb-2 mb-sm-0 mb-md-0'}
                    onClick={handleUpcomingToggle}
                />
                <SummaryItem
                    title={'Completed'}
                    subtitle={'Today'}
                    value={summary !== null ? summary.successful.length : null}
                    color={summary !== null && summary.successful.length > 0 ? `success` : `secondary`}
                    className={'col-6 col-sm-3 col-md-3 pe-2'}
                    onClick={handleCompletedToggle}
                />
                <SummaryItem
                    title={'Failed'}
                    subtitle={'Today'}
                    value={summary !== null ? summary.failed.length : null}
                    color={summary !== null && summary.failed.length > 0 ? `danger` : `success`}
                    className={'col-6 col-sm-3 col-md-3'}
                    onClick={handleFailedToggle}
                />
            </Row>
            <RunningOffcanvas summary={summary} runningShow={runningShow} handleRunningToggle={handleRunningToggle} />
            <FailedOffcanvas summary={summary} failedShow={failedShow} handleFailedToggle={handleFailedToggle} />
            <CompletedOffcanvas
                summary={summary}
                completedShow={completedShow}
                handleCompletedToggle={handleCompletedToggle}
            />
            <UpcomingOffcanvas
                summary={summary}
                upcomingShow={upcomingShow}
                handleUpcomingToggle={handleUpcomingToggle}
            />
        </Container>
    );
}

export default Summary;
