import React, { useState, useEffect } from 'react';
import './Workflows.css';

import { Table, Container, Button, Toast, Spinner, Accordion, ToastContainer, Modal, Form, Dropdown } from 'react-bootstrap';
import { CopyIcon, MarkGithubIcon, PlayIcon, RepoDeletedIcon } from '@primer/octicons-react';

import {
    Workflow,
    WorkflowGroup,
    copyToClipboard,
    getWorkflows,
    runWorkflow,
    syncSpecificWorkflows,
    syncWorkflows,
    getTaskDefinitions,
    retryJob,
} from '../../../services/jobs.service';

// TODO: FIX THE SORTING ENABLE DISABLE BUTTON
// TODO: FIX THE RESPONSIVENESS

function Workflows(props: any) {
    document.title = `Workflows` + process.env.REACT_APP_TITLE_POSTFIX;

    const [successMsg, setSuccessMsg] = useState<string | null>(null);
    const [successDescription, setSuccessDescription] = useState<string | null>(null);
    const [errorMsg, setErrorMsg] = useState<string | null>(null);
    const [workflows, setWorkflows] = useState<WorkflowGroup | undefined>(undefined);
    const [awaiting, setAwaiting] = useState(false);
    const [cancelHandler, setCancelHandler] = useState<AbortController | null>(null);
    const [showToast, setShowToast] = useState(false);
    const [showSyncToast, setShowSyncToast] = useState(false);

    const [updateRequired, setUpdateRequired] = useState(false);

    const [dataValid, setDataValid] = useState(false);
    const [showConfirmationModal, setShowConfirmationModal] = useState(false);
    const [jobToRun, setjobToRun] = useState({ workflowKey: '', jobId: '', revisionNumber: 0 });
    const [taskDefinitions, setTaskDefinitions] = useState<any>([]);

    useEffect(() => {
        props.setActive('workflows');
        fetchWorkflows();
        if (taskDefinitions.length === 0) {
            getTaskDefinitions(setCancelHandler).then((res) => {
                setTaskDefinitions(res);
            });
        }
    }, [taskDefinitions]);

    useEffect(() => {
        // console.log("Updated jobToRun:", jobToRun);
    }, [jobToRun]);

    function fetchWorkflows() {
        getWorkflows(setCancelHandler).then((res) => {
            setWorkflows(res);

            setUpdateRequired(false); //Setting it to false because an update was just provided
        });
    }

    function handleLaunchTask(workflowKey: string) {
        setjobToRun({ ...jobToRun, jobId: '' });
        setjobToRun({ ...jobToRun, revisionNumber: 0 });
        setjobToRun({ ...jobToRun, workflowKey: workflowKey });

        setShowConfirmationModal(true);

        // setAwaiting(true);
        // setSuccessMsg(null);
        // setErrorMsg(null);
        // // await runWorkflow(workflowKey, setSuccessMsg, setErrorMsg, setCancelHandler);
        // setAwaiting(false);
        // setShowToast(true);
    }

    function toggleToastShow() {
        setShowToast((value) => {
            return !value;
        });
    }

    function toggleSyncToast() {
        setShowSyncToast((value) => {
            return !value;
        });
    }

    function handleSync() {
        setAwaiting(true);
        setShowSyncToast(false); // Making sure that the toast is hidden
        setSuccessMsg('Sending request to synchronize');
        setSuccessDescription('Please wait...');
        setErrorMsg(null);

        syncWorkflows(setSuccessMsg, setSuccessDescription, setErrorMsg, setCancelHandler).finally(() => {
            setAwaiting(false);
        });

        toggleSyncToast();
    }

    function handleSpecificSync(basePath: string) {
        setAwaiting(true);
        setShowSyncToast(false); // Making sure that the toast is hidden
        setSuccessMsg('Sending request to synchronize');
        setSuccessDescription('Please wait...');
        setErrorMsg(null);

        syncSpecificWorkflows(basePath, setSuccessMsg, setSuccessDescription, setErrorMsg, setCancelHandler).finally(
            () => {
                setAwaiting(false);
            },
        );

        toggleSyncToast();
    }

    async function handleConfirmDeploy() {
        setAwaiting(true);
        setSuccessMsg(null);
        setErrorMsg(null);

        await retryJob(jobToRun.workflowKey, jobToRun.jobId, undefined, jobToRun.revisionNumber, setSuccessMsg, setErrorMsg, setCancelHandler);

        setAwaiting(false);
        setShowConfirmationModal(false);
        setShowToast(true);
        setjobToRun({ workflowKey: '', jobId: '', revisionNumber: 0 }); // Clear the jobToRun
    }

    function handleCancelDeploy() {
        setShowConfirmationModal(false);
        setjobToRun({ workflowKey: '', jobId: '', revisionNumber: 0 }); // Clear the jobToRun
    }

    function handleDataChange(event: any) {
        const value = event.target.type === 'checkbox' ? event.target.checked : event.target.value;
        setjobToRun({
            ...jobToRun,
            [event.target.name]: value,
        });
        // If the revision number is empty, set it to 0
        if (event.target.name === 'revisionNumber' && value === '') {
            setjobToRun({
                ...jobToRun,
                [event.target.name]: 0,
            });
        }
    }

    return (
        <>
            <Container fluid className="mt-3">
                <Button
                    className={`bg-transparent text-main border-0 rounded-pill fs-7 p-0 ${awaiting ? 'disabled' : ''}`}
                    onClick={handleSync}
                >
                    Synchronize
                </Button>
            </Container>
            <Container fluid className="bg-white mt-3 rounded-3 shadow-sm pb-0">
                {props.device.isMobile && (
                    <>
                        {workflows === undefined && (
                            <div className="d-flex justify-content-center gap-4 py-4 opacity-75">
                                <Spinner animation="grow" variant="primary" size="sm" />
                                <Spinner animation="grow" variant="primary" size="sm" />
                                <Spinner animation="grow" variant="primary" size="sm" />
                            </div>
                        )}
                        {workflows !== undefined && Object.keys(workflows).length === 0 && (
                            <div className="d-flex justify-content-center align-item-center gap-3 py-4 opacity-75">
                                <RepoDeletedIcon size={24} />
                                No Records Found!
                            </div>
                        )}
                        {workflows !== undefined && Object.keys(workflows).length > 0 && (
                            <Accordion flush>
                                {Object.keys(workflows).map((groupBase) => (
                                    <Accordion.Item eventKey={`${groupBase}`} key={groupBase}>
                                        <Accordion.Header>
                                            <div className="d-flex gap-2 text-break align-items-center">
                                                <span className="badge text-bg-secondary rounded-pill">
                                                    {workflows[groupBase as keyof typeof workflows].length}
                                                </span>
                                                {groupBase}
                                            </div>
                                        </Accordion.Header>
                                        <Accordion.Body>
                                            <Button
                                                className={`bg-transparent text-main border-0 rounded-pill fs-7 p-0 ${awaiting ? 'disabled' : ''
                                                    }`}
                                                onClick={() => handleSpecificSync(groupBase)}
                                            >
                                                Synchronize this Directory
                                            </Button>
                                            <Accordion
                                                flush
                                                onSelect={() => {
                                                    setSuccessMsg(null);
                                                    setErrorMsg(null);
                                                }}
                                            >
                                                {workflows[groupBase as keyof typeof workflows] instanceof Array &&
                                                    workflows[groupBase as keyof typeof workflows].map(
                                                        (workflow: Workflow) => (
                                                            <Accordion.Item
                                                                eventKey={`${workflow.key}`}
                                                                key={workflow.key}
                                                            >
                                                                <Accordion.Header>
                                                                    <div className="d-flex gap-2 text-break align-items-center">
                                                                        <span
                                                                            className={`badge rounded-pill text-bg-secondary`}
                                                                        >
                                                                            {workflow.basePath}
                                                                        </span>
                                                                        {workflow.path.replace(
                                                                            workflow.basePath + '/',
                                                                            '',
                                                                        )}
                                                                    </div>
                                                                </Accordion.Header>
                                                                <Accordion.Body>
                                                                    <div className="table-responsive">
                                                                        <table className="table">
                                                                            <tbody>
                                                                                <tr>
                                                                                    <th scope="row">Path</th>
                                                                                    <td>{workflow.path}</td>
                                                                                </tr>
                                                                                <tr>
                                                                                    <th scope="row">Created At</th>
                                                                                    <td>{workflow.createdAt}</td>
                                                                                </tr>
                                                                                <tr>
                                                                                    <th scope="row">Updated At</th>
                                                                                    <td>{workflow.updatedAt}</td>
                                                                                </tr>
                                                                                <tr>
                                                                                    <th scope="row">
                                                                                        Job Config Filename
                                                                                    </th>
                                                                                    <td>
                                                                                        {workflow.jobConfigFilename}
                                                                                    </td>
                                                                                </tr>
                                                                            </tbody>
                                                                        </table>
                                                                    </div>
                                                                    {successMsg && (
                                                                        <div
                                                                            className="alert alert-success"
                                                                            role="alert"
                                                                        >
                                                                            {successMsg}
                                                                        </div>
                                                                    )}
                                                                    {errorMsg && (
                                                                        <div
                                                                            className="alert alert-danger"
                                                                            role="alert"
                                                                        >
                                                                            {errorMsg}
                                                                        </div>
                                                                    )}
                                                                    <div className="d-flex gap-2 justify-content-end">
                                                                        <Button
                                                                            className={`bg-${awaiting
                                                                                ? 'secondary disabled'
                                                                                : 'primary'
                                                                                } border-0 rounded-5 mt-3 mobile-button-width`}
                                                                            onClick={() => {
                                                                                handleLaunchTask(workflow.key);
                                                                            }}
                                                                        >
                                                                            {awaiting && (
                                                                                <Spinner
                                                                                    animation="border"
                                                                                    className="w-10"
                                                                                    size="sm"
                                                                                />
                                                                            )}
                                                                            {!awaiting && <>Launch Task</>}
                                                                        </Button>
                                                                    </div>
                                                                </Accordion.Body>
                                                            </Accordion.Item>
                                                        ),
                                                    )}
                                            </Accordion>
                                        </Accordion.Body>
                                    </Accordion.Item>
                                ))}
                            </Accordion>
                        )}
                    </>
                )}
                {props.device.isMobile === false && (
                    <>
                        {workflows === undefined && (
                            <div className="d-flex justify-content-center gap-4 py-4 opacity-75">
                                <Spinner animation="grow" variant="primary" size="sm" />
                                <Spinner animation="grow" variant="primary" size="sm" />
                                <Spinner animation="grow" variant="primary" size="sm" />
                            </div>
                        )}
                        {workflows !== undefined && Object.keys(workflows).length === 0 && (
                            <div className="d-flex justify-content-center align-item-center gap-3 py-4 opacity-75">
                                <RepoDeletedIcon size={24} />
                                No Records Found!
                            </div>
                        )}
                        {workflows !== undefined && Object.keys(workflows).length > 0 && (
                            <Accordion flush>
                                {Object.keys(workflows).map((groupBase) => (
                                    <Accordion.Item eventKey={`${groupBase}`} key={groupBase}>
                                        <Accordion.Header>
                                            <div className="d-flex gap-2 text-break align-items-center">
                                                <span className="badge text-bg-secondary rounded-pill">
                                                    {workflows[groupBase as keyof typeof workflows].length}
                                                </span>
                                                {groupBase}
                                            </div>
                                        </Accordion.Header>
                                        <Accordion.Body>
                                            <Button
                                                className={`bg-transparent text-main border-0 rounded-pill fs-7 p-0 ${awaiting ? 'disabled' : ''
                                                    }`}
                                                onClick={() => handleSpecificSync(groupBase)}
                                            >
                                                Synchronize this Directory
                                            </Button>
                                            <Table className="my-1 fs-7" hover>
                                                <thead className="border-bottom">
                                                    <tr>
                                                        <th className="fw-heavy text-gray">Name</th>
                                                        <th className="fw-heavy text-gray">Created At</th>
                                                        <th className="fw-heavy text-gray">Updated At</th>
                                                        <th className="fw-heavy text-gray">Workflow Key</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {workflows[groupBase as keyof typeof workflows] instanceof Array &&
                                                        workflows[groupBase as keyof typeof workflows].map(
                                                            (workflow: Workflow) => (
                                                                <tr key={workflow.key}>
                                                                    <td className="d-flex gap-2 text-break">
                                                                        <Button
                                                                            className={`bg-transparent ${awaiting ? 'disabled' : ''
                                                                                } text-main border-0 p-0`}
                                                                            onClick={() => {
                                                                                handleLaunchTask(workflow.key);
                                                                            }}
                                                                        >
                                                                            <PlayIcon size={24} />
                                                                        </Button>
                                                                        {workflow.path}
                                                                    </td>
                                                                    <td className="text-break">{workflow.createdAt}</td>
                                                                    <td className="text-break">{workflow.updatedAt}</td>
                                                                    <td className="text-break">
                                                                        {workflow.key}
                                                                        <button
                                                                            className="bg-transparent p-0 ms-2 border-0"
                                                                            onClick={() =>
                                                                                copyToClipboard(workflow.key)
                                                                            }
                                                                        >
                                                                            <CopyIcon size={14} />
                                                                        </button>
                                                                    </td>
                                                                </tr>
                                                            ),
                                                        )}
                                                </tbody>
                                            </Table>
                                        </Accordion.Body>
                                    </Accordion.Item>
                                ))}
                            </Accordion>
                        )}
                        <ToastContainer containerPosition="fixed" className="m-4" position="top-center">
                            <Toast
                                show={showToast}
                                onClose={toggleToastShow}
                                bg={`${successMsg !== null ? 'success' : 'danger'}`}
                                autohide
                            >
                                <Toast.Header>
                                    <strong className="me-auto">
                                        {successMsg !== null && <>{successMsg}</>}
                                        {errorMsg !== null && <>{errorMsg}</>}
                                    </strong>
                                    <small className="text-muted">just now</small>
                                </Toast.Header>
                            </Toast>
                            <Toast show={showSyncToast} onClose={toggleSyncToast}>
                                <Toast.Header>
                                    <MarkGithubIcon size={16} />
                                    <strong className="me-auto ms-2">
                                        {successMsg !== null && <>{successMsg}</>}
                                        {errorMsg !== null && <>{errorMsg}</>}
                                    </strong>
                                    <small className="text-muted">just now</small>
                                </Toast.Header>
                                <Toast.Body>
                                    {successMsg !== null && successDescription !== null && <>{successDescription}</>}
                                    {errorMsg !== null && (
                                        <>There seems to be a problem with the process. Please try again later.</>
                                    )}
                                </Toast.Body>
                            </Toast>
                        </ToastContainer>
                    </>
                )}
            </Container>
            {showConfirmationModal && (
                <Modal show={showConfirmationModal} onHide={handleCancelDeploy}>
                    <Modal.Header closeButton>
                        <Modal.Title>Run this job</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        {taskDefinitions.length === 0 && (
                            // If workFlowKeys is empty, show a spinner (response is being awaited)
                            <div className="text-center">
                                <Spinner animation="border" role="status"></Spinner>
                            </div>
                        )}
                        {taskDefinitions.length > 0 && (
                            <Form onSubmit={(e) => e.preventDefault()}>
                                <Form.Group className="mb-3">
                                    <Form.Control
                                        name="jobId"
                                        type="text"
                                        placeholder="Enter Job Id (Optional)"
                                        onChange={handleDataChange}
                                        className="rounded-0 text-muted"
                                    />
                                    <Form.Text className="text-muted"><div><br /> </div></Form.Text>
                                    <Form.Control
                                        name="revisionNumber"
                                        as="select"
                                        onChange={handleDataChange}
                                        className="rounded-0 text-muted"
                                    >
                                        <option value="">Select System Configuration (Optional)</option>
                                        {taskDefinitions.map((task: any) => (
                                            <option className="text-dark" key={task.revision} value={task.revision}>
                                                CPU: {task.cpu} Memory: {task.memory} Mbs
                                            </option>
                                        ))}

                                    </Form.Control>
                                </Form.Group>
                            </Form>
                        )}
                    </Modal.Body>
                    <Modal.Footer>
                        <Button className="bg-secondary rounded-0 border-0 modal-button-width" onClick={handleCancelDeploy}>
                            Cancel
                        </Button>
                        <Button
                            onClick={handleConfirmDeploy}
                            className={`bg-${awaiting ? 'secondary' : 'main-1'
                                } rounded-0 border-0 modal-button-width`}
                            disabled={taskDefinitions.length === 0}
                        >
                            {awaiting && (
                                <Spinner animation="border" className="w-10" size="sm" />
                            )}
                            {!awaiting && <>Deploy</>}
                        </Button>
                    </Modal.Footer>
                </Modal>
            )}
        </>
    );
}

export default Workflows;
